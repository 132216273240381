








































import { Component, Ref } from "vue-property-decorator";
import CommonList from "@/commons/includes/common-list";

import adminForm from "./admin-form.vue";
import adminToolbar from "./toolbar.vue";

@Component({
  components: {
    adminForm,
    adminToolbar,
  },
})
export default class AdminClientsTools extends CommonList<any> {
  protected tblHeight = window.innerHeight;
  @Ref("bar") protected refBar!: any;
  protected tblHeaders = [
    { value: "clientName", text: "Название", sortable: false },
    { value: "clientId", text: "Строковый id", sortable: false },
    { value: "redirectUris", text: "Redirect Uris", sortable: false },
  ];

  protected mounted() {
    this.tblHeight = window.innerHeight - this.refBar.$el.offsetHeight - 182;
  }

  get lengthPage() {
    if (!this.totalRowsCount || !this.tblOptions.itemsPerPage || this.tblOptions.itemsPerPage < 0) return 0;
    const length = Number(Math.ceil(this.totalRowsCount / this.tblOptions.itemsPerPage));
    return length;
  }

  protected ChipColor(item: any) {
    if (item) return "success";
  }

  protected apiUrl() {
    return `v1/client`;
  }

  protected openItem(item: App.User) {
    this.$go(`/admin/clients/${item.id}`);
  }

  private itemCreate() {
    this.readList();
  }

  private changePage(val: any) {
    this.tblOptions.page = val.page;
    this.tblOptions.itemsPerPage = val.itemsPerPage;
  }

  private onFilter(input: any) {
    this.apiFilters = input;
  }
}
