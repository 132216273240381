<template>

  <span class="table-pages-stat">
      {{recordsFrom}} - {{recordsTo}} из {{allRecordsCount}}
  </span>

</template>

<script>
export default {


  props: {
    pageSize:        { type: Number, default: 0 },
    page:            { type: Number, default: 0 },
    displayedRecordsCount:{ type: Number, default: 0 },
    allRecordsCount: { type: Number, default: 0 },
  },

  computed: {
    pageNum(){   return this.page + 1; },
    recordsFrom(){ return this.pageSize * (this.page) + 1; },
    recordsTo(){   return this.recordsFrom + this.displayedRecordsCount - 1; },
  },

  methods: {
    emitClick(){ this.$emit('cellClick', this.cell); },
  }
};
</script>
<style lang="scss">
.table-pages-stat{
 font-size: 0.8em;
 opacity: 0.7;
}
</style>
