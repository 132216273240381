












































import { Component, Ref } from "vue-property-decorator";
import CommonList from "@/commons/includes/common-list";

import adminForm from "./admin-form.vue";
import adminToolbar from "./toolbar.vue";

@Component({
  components: {
    adminForm,
    adminToolbar,
  },
})
export default class AdminUsersTools extends CommonList<any> {
  protected tblHeight = window.innerHeight;
  @Ref("bar") protected refBar!: any;
  protected tblHeaders = [
    { value: "gender", text: "Пол", width: 100, sortable: false },
    { value: "id", text: "Id", sortable: false },
    { value: "username", text: "Пользователь", sortable: false },
    { value: "name", text: "ФИО", sortable: false },
    { value: "email", text: "Email", sortable: false },
    { value: "roles", text: "Роли", sortable: false },
  ];

  protected mounted() {
    this.tblHeight = window.innerHeight - this.refBar.$el.offsetHeight - 182;
  }

  get lengthPage() {
    if (!this.totalRowsCount || !this.tblOptions.itemsPerPage || this.tblOptions.itemsPerPage < 0) return 0;
    const length = Number(Math.ceil(this.totalRowsCount / this.tblOptions.itemsPerPage));
    return length;
  }
  get getNickName() {
    if (this.activeId === "_new") return "Новый пользователь";
    if (!this.activeId || !this.xList || !this.xList.length) return "";
    const user = this.xList.find(u => Number(u.id) == this.activeId);
    if (!user) return "";
    return user.nickname;
  }

  protected ChipColor(item: any) {
    if (item) return "success";
  }

  protected apiUrl() {
    return `v1/user`;
  }
  protected apiUrlCount() {
    return `v1/user/count`;
  }

  protected openItem(item: App.User) {
    this.$go(`/admin/users/${item.id}`);
  }

  private itemCreate() {
    this.readList();
  }

  private changePage(val: any) {
    this.tblOptions.page = val.page;
    this.tblOptions.itemsPerPage = val.itemsPerPage;
  }

  private onFilter(input: any) {
    this.apiFilters = input;
  }
}
