<template>
  <span v-if="value" class="license-plate">
    <span class="num" v-html="number"></span>
    <span class="reg" v-html="region"></span>
  </span>
  <span v-else-if="altName" class="license-plate">{{ altName }}</span>
  <span v-else class="license-plate">не указано</span>
</template>

<script>
/** View of cars license plate */

export default {
  name: "LicensePlate",

  props: {
    value: { type: String, required: true },
    altName: { type: String },
    emphasis: { type: String },
  },

  computed: {
    regStart() {
      return this.indexOfLastDigits(this.value);
    },
    empLen() {
      if (!this.value) return 0;
      if (!this.emphasis) return 0;
      if (this.value.indexOf(this.emphasis) == -1) return 0;
      return this.emphasis.length;
    },
    empStart() {
      if (!this.empLen) return 0;
      return this.value.indexOf(this.emphasis);
    },
    empEnd() {
      if (!this.empLen) return 0;
      return this.empStart + this.empLen;
    },
    number() {
      if (this.regStart === -1) return this.value;
      let num = this.value.substr(0, this.regStart);

      // if emphasised characters exists and starts in number
      if (this.empLen && this.empStart < this.regStart) {
        // end is position of emphasis end or end of num
        const eEnd = this.empEnd < this.regStart ? this.empEnd : this.regStart;
        num = num.substring(0, eEnd) + "</span>" + num.substring(eEnd);
        num = num.substring(0, this.empStart) + "<span class='emphasis'>" + num.substring(this.empStart);
      }
      return num;
    },
    region() {
      if (this.regStart === -1) return "";
      let reg = this.value.substring(this.regStart);

      // if emphasised characters exists and ends in region
      if (this.empLen && this.empEnd > this.regStart) {
        let eStart = this.empStart - this.regStart;
        if (eStart < 0) eStart = 0;
        const eEnd = this.empEnd - this.regStart;

        reg = reg.substring(0, eEnd) + "</span>" + reg.substring(eEnd);
        reg = reg.substring(0, eStart) + "<span class='emphasis'>" + reg.substring(eStart);
      }
      return reg;
    },
  },

  methods: {
    indexOfLastDigits(val) {
      let match = false;
      let idx = val.length - 1;
      // decrement idx while digit at idx position
      while (idx >= 0 && /\d/.test(val.substr(idx, 1))) {
        idx--;
        match = true;
      }
      return match ? idx + 1 : -1;
    },
  },
};
</script>

<style lang="scss">
$lp-bg: #fff;
$lp-txt: #555;
$lp-ln: rgba(
  $color: #555,
  $alpha: 0.3,
);

.license-plate {
  display: inline-block;
  border: 1px solid $lp-ln;
  padding: 0px 3px;
  color: $lp-txt;

  .num {
    display: inline-block;
    min-width: 4.5em;
    text-align: center;
  }
  .reg {
    display: inline-block;
    padding-left: 3px;
    border-left: 1px solid $lp-ln;
    min-width: 2em;
    text-align: center;
  }
  .emphasis {
    background-color: #f6d155;
  }
}

.in-map-viewport {
  .license-plate {
    font-weight: bold;
    border-color: darken($lp-ln, 50%);
    .reg {
      border-color: darken($lp-ln, 50%);
    }
  }
}
.selected {
  .license-plate {
    background-color: #f6d155;
  }
}
</style>
