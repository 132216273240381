<template>
  <div class="w-circle-spinner" v-if="show">
    <div class="w-circle-spinner__outer" :style="styleData" />
    <div class="w-circle-spinner__inner" :style="styleData" />
  </div>
</template>

<script lang="js">
export default {
  name: "spinner",

  props: {
    show: { type: Boolean, default: true },
    white: { type: Boolean, default: false },
    black: { type: Boolean, default: false }
  },

  computed: {
    color() {
      if (this.white) return "#ffffff";
      if (this.black) return "#000000";
      return "";
    },
    styleData() {
      const data = {};
      if (this.color) {
        data.borderTopColor = this.color;
        data.borderBottomColor = this.color;
      }
      return data;
    }
  }
};
</script>

<style lang="scss">
.w-circle-spinner {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  position: relative;

  box-sizing: border-box;

  &__outer,
  &__inner {
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #555;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__outer {
    border-left-color: transparent;
    border-right-color: transparent;
    animation: wc-rotate 1s linear infinite reverse;
  }
  &__inner {
    position: relative;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-left-color: transparent;
    border-right-color: transparent;
    animation: wc-rotate 1s linear infinite;
  }
}

@keyframes wc-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
