<template>
  <span class="time-ago">{{ str }}</span>
</template>

<script>
import moment from "moment";

export default {
  name: "time-ago",

  props: {
    value: { type: Number },
  },

  beforeMount() {
    this.interval = setInterval(() => {
      this.updateStr();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },

  data() {
    return {
      interval: null,
      str: "время не указано",
    };
  },

  methods: {
    updateStr() {
      if (!this.value) return;
      if (Date.now() - this.value < 3000) {
        this.str = "только что";
        return;
      }
      this.str = moment(this.value).fromNow();
    },
  },
};
</script>

<style lang="scss"></style>
