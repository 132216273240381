


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DraweredVueBlockNew extends Vue {
  @Prop({ type: Boolean, default: true }) public open!: boolean;
  @Prop({ type: String, default: "340px" }) public drawerSize!: string;

  private localOpen = true;

  private beforeMount(): void {
    this.assignData();
  }
  @Watch("open")
  private onOpenChange() {
    this.assignData();
  }
  private assignData() {
    this.localOpen = this.open;
  }

  get drawerStyle() {
    return this.localOpen ? { width: this.drawerSize } : { width: 0 };
  }
}
