






import { Component, Vue } from "vue-property-decorator";

/** Отображение ошибок, которые берутся из свойства error родительского компонента
 *  на самом деле не прямого родителя, ведь он может быть обернут в родителе чем угодно
 */
@Component({
  components: {},
})
export default class ShowError extends Vue {
  ancestor = null;

  mounted() {
    this.ancestor = this.findAncestorWithError();
    if (this.ancestor === undefined)
      // eslint-disable-next-line
      console.warn(
        "Компонент show-error не может найти свойство 'error' ни у одного из родительских компонентов. Вероятно, вы забыли его объявить, иначе нет смысла использовать show-error"
      );
  }

  get ancestorError() {
    if (!this.ancestor) return null;
    return (this.ancestor as any).error;
  }

  get errorMessage(): string {
    if (!this.ancestorError) return "";
    const errs = Array.isArray(this.ancestorError) ? this.ancestorError : [this.ancestorError];
    return errs.map(e => this.getErrMessage(e)).join(". ");
  }

  getErrMessage(err: any) {
    if (typeof err === "string") return err;
    return err.message || JSON.stringify(err);
  }

  findAncestorWithError() {
    let ancestor = this.$parent as any;
    while (ancestor.error === undefined) {
      ancestor = ancestor.$parent;
      if (ancestor === undefined) return undefined;
    }
    return ancestor;
  }
}
