<template>
  <!-- <span :class="cssClass"></span> -->
  <v-icon v-bind="$attrs">mdi-{{ name }}</v-icon>
</template>

<script>
// https://materialdesignicons.com/

export default {
  name: "mdi",
  inheritAttrs: false,

  props: {
    name: { type: String, default: "file" },
  },

  computed: {
    isOutlined() {
      return this.outline || this.line || this.ln;
    },
    cssClass() {
      let css = "mdi mdi-" + this.name;
      if (this.isOutlined) css += "-outline";
      return css;
    },
  },
};
</script>

<style lang="scss">
// @import "../../node_modules/@mdi/font/scss/materialdesignicons";
// @import "../../node_modules/@mdi/font/css/materialdesignicons.css";
</style>
