














import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

/**
 Отрабатывает состояния, которые возникают при подгрузке данных через API с удаленного сервера
 Этих состояний несколько:
 not-initialized: данных нет и они не запрашивались
 pending: данных нет, они в процессе запроса
 not-empty: данные успешно пришли с сервера, есть что показывать
 empty: данные успешно пришли с сервера, но запись пустая
 error: данные не удалось загрузить из-за ошибки
 refreshing: данные есть и они в процессе запроса
 */

@Component({})
export default class RemoteData extends Vue {
  /** Данные, которые грузятся с сервера */
  @Prop({ type: [Object, Array, Boolean] }) public data: any;
  /** Флаг, что данные в процессе запроса */
  @Prop({ type: Boolean }) public pending!: boolean;
  /** Ошибка */
  @Prop({ type: [Object, String, Error] }) public error!: any;

  /** сообщение об отсутствии данных, если пустая строка то выводится слот с данными -
   * чтобы можно было делать пользовательскую вёрстку пустых данных    */
  @Prop({ type: [String], default: "Данных нет" }) public nullText!: any;
  /** сообщение о пустом списке, если пустая строка то выводится слот с данными*/
  @Prop({ type: [String], default: "Список пуст" }) public emptyListText!: any;


  @Watch('error')
  errorChanged() {
    // console.log('error', this.error);
  }

  get showError() {
    if (!this.error) return false;
    if (this.pending) return false;
    return true;
  }
  get errorStr() {
    if (!this.error) return '';
    if (this.error instanceof Error) return this.error.message;
    if (typeof this.error === 'string') return this.error;
    return JSON.stringify(this.error);
  }
  get isEmpty() {
    if (this.pending) return false;
    if (!Array.isArray(this.data)) return false;
    return this.data.length === 0;
  }
  get noData() {
    if(this.pending) return false;
    if(this.data === null) return true;
    if(this.data === undefined) return true;
    return false;
  }
  get notInitialized() {
    if (this.data) return false;
    if (this.error) return false;
    if (this.pending) return false;
    return true;
  }
}
