import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import "./attach-globals";
import "./assets/styles/styles.scss";

Vue.config.productionTip = false;

import "./commons/includes/vue-shugar";
import "./commons/includes/vue-rights-utils";
import "./commons/components/_attach";
import "./commons/widgets/_attach";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");

