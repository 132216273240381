























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import rest from "@/utils/rest";

function defaultOnLoad(response: any): any {
  return response.content;
}

@Component({
  components: {},
})
export default class ModelSelect extends Vue {
  @Prop() public value!: any;
  @Prop({ type: String }) public url!: string;
  @Prop({ type: Function, default: defaultOnLoad }) public onLoad!: (response: any) => any;
  @Prop({ type: String, default: "name" }) public nameIs!: string;
  @Prop({ type: [String, Array, Function], default: "sysName" }) public valueIs!: any;

  public inheritAttrs = false;
  private list: any[] = [];
  private error: any = null;
  private localValue: string | null = "";

  private beforeMount() {
    this.localValue = this.value;
  }

  @Watch("value")
  private onValueChange() {
    this.localValue = this.value;
  }

  get isObject() {
    return this.valueIs === null;
  }

  private load() {
    this.error = "";
    const url = this.url;
    rest
      .get(url)
      .then((res: any) => {
        this.list = this.onLoad ? this.onLoad(res) : res;
        if (!this.list) this.list = [];
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      });
  }

  private mounted() {
    this.load();
  }
}
