






















































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { clone } from "@/utils/utils-obj";

@Component({
  components: {},
})
export default class DateInput extends Vue {
  @Prop({ type: [String] }) public value!: string | null;
  @Prop({ type: [String] }) public defaultTime!: string | null;

  private showCalendar = false;
  private activeTab = 0;
  private localValue: string | null = null;
  private date: string | null = null;
  private time: string | null = null;

  @Ref("timer") private timer!: any;

  private beforeMount() {
    this.time = this.defaultTime ? clone(this.defaultTime) : null;
    this.assignValue();
  }

  @Watch("activeTab")
  private onTabChange() {
    if (this.timer) {
      this.timer.selectingHour = true;
    }
  }
  @Watch("value")
  private onValueChange() {
    this.assignValue();
  }
  private assignValue() {
    this.localValue = this.value ? clone(this.value) : null;
  }

  private showTimePicker() {
    this.activeTab = 1;
  }
  private clearHandler() {
    this.resetPicker();
    this.localValue = null;
    this.date = null;
    this.time = this.defaultTime ? this.defaultTime : null;
    this.$emit("input", null);
  }
  private onInput() {
    this.resetPicker();
    this.$emit("input", this.selectedDatetime);
  }
  private resetPicker() {
    this.showCalendar = false;
    this.activeTab = 0;
  }
  get selectedDatetime() {
    if (this.date && this.time) {
      const date = new Date(this.date + " " + this.time);
      return moment(date).utc().format();
    } else {
      return null;
    }
  }
  get dateSelected() {
    return !this.date;
  }
  get displayDate() {
    if (this.selectedDatetime) this.localValue = this.selectedDatetime;
    if (!this.localValue) return "";
    return moment(this.localValue).format("DD MMM YYYY HH:mm");
  }
}
