










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import topMenu from "./elements/app-top-menu.vue";
import appAsideMenu from "./elements/app-aside-menu.vue";
import appUser from "./elements/app-user.vue";
import appCluster from "./elements/app-cluster.vue";

@Component({
  components: {
    topMenu,
    appAsideMenu,
    appUser,
    appCluster
  },
})
export default class AppLayout extends Vue {
  @Prop({ type: Boolean }) public noScroll!: boolean;
  @Prop({ type: Boolean }) public noPad!: boolean;

  public drawer = null;
  mounted() {
    this.applyStyles();
  }
  @Watch("appSettings")
  onSettingsChange() {
    this.applyStyles();
  }

  /** параметры, прочитанные из таблицы БД app_settings */
  get appSettings() {
    return this.$store.state.App.settings as { [key: string]: string } | null;
  }
  /** адрес перехода с логотипа из таблицы БД app_settings */
  get logoHref() {
    if (!this.appSettings) return "/";
    return this.appSettings.home_uri || "/";
  }
  /** цвет фона логотипа из таблицы БД app_settings */
  get logoBg() {
    if (!this.appSettings) return null;
    return this.appSettings.logo_bg || null;
  }
  /** SVG код логотипа из таблицы БД app_settings */
  get logoSvg() {
    const defaultCode = `<div style="opacity: 0.6; margin-top:20px;">
    <i aria-hidden="true" class="v-icon notranslate mdi mdi-radio-tower"></i></div>`;

    if (!this.appSettings) return defaultCode;
    return this.appSettings.logo_svg || defaultCode;
  }

  /** переход по ссылке, если начинается с http:// то через window.location
   * иначе переход по $router.push  */
  go() {
    const pref = this.logoHref.substring(0, 8).toLowerCase();
    debugger
    if (pref === "http://" || pref === "https://") {
      // window.location.href = this.logoHref;
      window.location.replace(this.logoHref);
    } else {
      this.$go(this.logoHref);
    }
  }

  /** в БД хранятся некоторые CSS параметры, делаем из них CSS классы,
   *  которыми можно пользоваться в приложении и добавляем их как style элемент в html.head */
  applyStyles() {
    let styleEl = document.getElementById("app-styles");
    if (!styleEl) {
      styleEl = document.createElement("style");
      styleEl.id = "app-styles";
      document.head.appendChild(styleEl);
    }
    // устанавливаем фавикон приложения
    const favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute('href', `${this.appSettings?.favicon}`);

    // устанавливаем Title приложения
    const title = document.createElement('title');
    title.textContent = `Единый Сервис Авторизации ${this.appSettings?.login_title}`
    const head = document.querySelector('head');
    head?.appendChild(title);

    const brandColorSettings = this.appSettings?.brand_color_top_nav || this.appSettings?.brand_color
    const brandColor = brandColorSettings || "#616161";

    const css = `
    .brand-color, .brand-bg {background-color: ${brandColor} !important; color: #ffffff;}
    `;
    styleEl.innerHTML = css;
  }
}
