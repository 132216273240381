import { clone, SET } from "../includes/commons";
import initials from "./initials.json";
import router from "@/router";
import rest from "@/utils/rest";
import { ActionContext } from "vuex";
import { AppStore } from "./initials";
import mktoast from "mk-toast";

const state = clone(initials);

function replaceRouteOnce(path: string) {
  if (router.currentRoute.path === path) return;
  router.replace(path);
}
export default {
  strict: true,
  namespaced: true,

  state,

  mutations: {
    SET,
  },

  actions: {
    /** установки приложения из базы данных */
    readSettingsOnce(context: ActionContext<AppStore, any>) {
      const { state, commit } = context;
      if (state.settings) return;

      return rest
        .get("v1/app/settings/")
        .then(settings => {
          const mapSettings: any = {};
          settings.forEach((s: any) => {
            mapSettings[s.key] = s.value;
          });
          commit("SET", { settings: mapSettings });
          return "JKHGKJHGBJHKGBJH";
        })
        .catch(e => {
          mktoast.danger("Не удалось загрузить установки приложения из базы");
        });
    },
    authorizeReadState(context: Storage) {
      // Store.dispatch("Vehicles/readList").then(() => Store.dispatch("Vehicles/watchSnapshot"));
      // rest
      //   .get("userinfo")
      //   .then((user: App.User) => {
      //     console.log(user)
      //     context.commit("SET", { user });
      //   })
      //   .catch((e: Error) => {
      //     console.error(e);
      //     window.location.href = "http://localhost:8031/login"
      //   });
    },
  },
};
