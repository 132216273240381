































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
@Component({
  components: {},
})

/**
 *  @param { label } название input'а
 *  @return chosen-items { date: string, time: string }
 *
 */
export default class dateTimeSeparated extends Vue {
  @Prop() label: any;
  @Prop({
    type: Object,
    default: function() {
      return {
        date: moment().format('YYYY-MM-DD'),
        time: moment().format("HH:mm")
      }
    }
  })
  data!: { date: string, time: any}
  protected localDate: string = moment().format('YYYY-MM-DD');
  protected dateModal = false;
  protected localTime = moment().format("HH:mm");
  protected timeModal = false;
  private get dateRu(){
    return moment(this.localDate).format('ll');
  }
  private emitItems(){
    this.$emit('chosen-items', {
      date: this.localDate,
      time: this.localTime
    })
  }
  @Watch('data')
  onDateChange(){
    this.assignDate()
  }
 protected beforeMount() {
    this.assignDate()
  }

  protected assignDate() {
    if (this.data.date) this.localDate = moment(this.data.date).format("YYYY-MM-DD");
    if (this.data.time) this.localTime = this.data.time;
  }
}
