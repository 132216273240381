<template>
  <v-table
    :rows="pagedRows"
    :schema="adoptedSchema"
    :deletable="deletable"
    :selectable="selectable"
    :multisort="multisort"
    :selected="selected"
    :activatable="activatable"
    :groupBy="groupBy"

    :pageSize="pageSize"
    :page="page"
    :allRecordsCount="filteredCount"
    :displayedRecordsCount="pagedCount"
    @pageChange="page = $event; $emit('pageChange', $event)"

    @searchInput="onSearch"
    @filterInput="onFilter"
    @sort="onSort"

    @cellClick="$emit('cellClick', $event)"
    @recordClick="$emit('recordClick', $event)"
    @recordActivate="$emit('recordActivate', $event)"
    @recordDelete="$emit('recordDelete', $event)"
    @selectionAdd="$emit('selectionAdd', $event)"
    @selectionRemove="$emit('selectionRemove', $event)"
    @select="$emit('select', $event)"
  />

</template>

<script>
/**
 * Надстройка над vTable, которая управляет данными - сортировка, поиск, фильтрация, пагинация
 *
 */
import vTable from './index'
import normalize  from './components/normalize';


export default {
  name: 'mk-grid',

  components: {vTable},

  props: {
    // data and config
    records:      { type: Array },
    schema:       { type: Array },
    // behaviour
    selectable:   { type: Boolean, default: false },
    activatable:  { type: Boolean, default: false },
    deletable:    { type: Boolean, default: false },
    multisort:    { type: Boolean, default: false },
    selected:     { type: Array, default(){ return []; } },
    // display config
    tableClass:   { type: String, required: false, default: 'table table-striped table-bordered table-hover' },
    nullText:     { type: String, default: 'Ожидаем данные...' },
    zeroText:     { type: String, default: 'Нет записей' },
    nullClass:    { type: String, default: 'alert alert-info' },
    zeroClass:    { type: String, default: 'alert alert-warnig' },
    trClass:      { type: [String, Function] },
    groupBy:      { type: String },
    // pagination
    pageSize:     { type: Number, default: 0 },
  },

  data(){ return {
    filter: null,
    search: null,
    sort: null,
    page: 0,
  }; },

  computed: {
    normSchema(){
      if(this.schema) return normalize.recordSchema(this.schema);
      if(!this.records) return console.warn('Impossible to normalize schema, because no schema neither records given');
      return normalize.createSchema(this.records[0]);
    },
    rowsList(){
      normalize.schema = this.normSchema;
      if(!this.records) return null;
      return normalize.rowList(this.records);
    },
    filteredRows(){
      if(!this.rowsList) return null;
      let rows;
      if(this.filter && this.filter.length || this.search && this.search.length){
        rows = this.rowsList.filter( this.rowIsFit.bind(this) );
      } else {
        // clone array (tear off) otherwise "sort" does not work
        rows = this.rowsList.filter(()=>true);
      }
      if(this.sort && this.sort.key){
        rows.sort(this.compareRows.bind(this));
      }
      return rows;
    },
    filteredCount(){
      if(!this.filteredRows) return 0;
      return this.filteredRows.length;
    },
    pagedRows(){
      if(!this.pageSize) return this.filteredRows;
      const from = this.pageSize * this.page;
      let to = from + this.pageSize;
      if(to > this.filteredCount) to = this.filteredCount;

      const res = [];
      for(let i = from; i < to; i++ ) res.push(this.filteredRows[i]);
      return res;
    },
    filterOptions(){
      if(!this.normSchema){ return null; }
      if(!this.rowsList) return null;
      const res = {};
      this.normSchema.forEach(cellSchema => {
        if(!cellSchema.filterable) return;
        if(cellSchema.filterable === true){
          const optset = new Set(
            this.rowsList.map(row => this.getCellRVal(row, cellSchema.key))
          );
          res[cellSchema.key] = Array.from(optset);
        } else {
          res[cellSchema.key] = cellSchema.filterable;
        }
      })
      return res;
    },
    pagedCount(){ return this.pagedRows.length; },
    adoptedSchema(){
      return this.normSchema.map(cellSchema => {
        const res = {...cellSchema};
        // calculate filter options for schema
        if(res.filterable === true) {
          if(this.filterOptions && this.filterOptions[res.key]){
            res.filterable = this.filterOptions[res.key];
          } else {
            res.filterable = [];
          }
        }
        return res;
      })
    },
  },

  methods: {
    getCell(row, key){ return row.cells.find(cell => cell.schema.key === key); },
    getCellRVal(row, key){
      const cell = this.getCell(row, key);
      if(!cell) return;
      return cell.renderedValue;
    },
    compareRows(a,b){
      const {key, direction} = this.sort;
      if(!key) return 0;
      let aRVal = this.getCellRVal(a, key);
      let bRVal = this.getCellRVal(b, key);

      let comprison = (aRVal > bRVal)? 1 : -1;
      if(direction === 'desc') comprison *= -1;
      return comprison;
    },
    rowIsFit(row){
      // debugger;
      if(this.filter && this.filter.length){
        for(let i=0; i<this.filter.length; i++){
          let {key, value} = this.filter[i];
          let td = this.getCell(row, key);
          if(td === undefined) return true;
          if(td.renderedValue !== value) return false;
        }
      }
      if(this.search && this.search.length){
        for(let i=0; i<this.search.length; i++){
          let {key, value} = this.search[i];
          value = value.toLowerCase();
          let td = this.getCell(row, key);
          if(td === undefined) return true;
          if(!td.renderedValue.toLowerCase().includes(value)) return false;
        }
      }
      return true;
    },
    getFilterOptions(){},
    onSort(sort){
      this.sort = sort;
      this.$emit('sort', sort);
    },
    onSearch(search){
      this.search = search;
      this.$emit('searchInput', search);
    },
    onFilter(filter){
      this.filter = filter;
      this.$emit('filterInput', filter);
    }
  },

};
</script>

<style lang="scss">

</style>
