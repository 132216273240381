



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ModalView extends Vue {
  public inheritAttrs = false;

  @Prop({ type: Boolean, default: false }) public visible!: boolean;
  @Prop({ type: String }) public title!: string;
  @Prop({ type: Boolean }) public noClose = false;
  @Prop({ type: String, default: "Закрыть" }) public okBtnName!: string;

  private localValue = false;

  beforeMount() {
    // Й0банный вьютифай не успевает примонтировать свой диалог
    setTimeout(() => (this.localValue = this.visible), 100);
  }
  @Watch("visible")
  onIdChange() {
    this.localValue = this.visible;
  }
}
