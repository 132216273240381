import { ListRequest } from "./types";

/** adds "/{id}" to path and pushes it */
export function openItem(this: any, item: any) {
  if (!item) return;

  // take first 2 non-empty chunks of path
  const pathChunks = (this.$route.path as string)
    .replace(/\/$/, "") // clean extra slashes
    .split("/")
    .filter(e => Boolean(e)) // non empty
    .filter((e, i) => i < 2); // first two

  const id = typeof item === "string" || typeof item === "number" ? item : item.id || item.sysName;
  if (id === undefined || id == null) throw new Error("Отсутствует идентификатор экземпляра");
  pathChunks.push(id);
  this.$go("/" + pathChunks.join("/"));
}

/** removes last path dirname "/{id}"  and pushes it */
export function closeItem(this: any) {
  // const lastDir = /\/[^/]+\/{0,}$/;
  // this.$go(this.$route.path.replace(lastDir, ""));
  const chunks = this.$route.path.replace(/\/$/, "").split("/");
  if (chunks.length < 2) return;
  chunks.pop();
  const destination = chunks.join("/");
  this.$go(destination);
}

export function tblOptionsToRequestParams(req: Vuetify.TblOptions): ListRequest {
  const obj: ListRequest = {
    page: null,
    size: null,
    sortKey: null,
    sortDir: null,
  };
  if (req.itemsPerPage !== -1) {
    obj.size = req.itemsPerPage;
    if (req.page) obj.page = req.page - 1;
  }
  if (req.sortDesc) {
    if (req.sortDesc[0] !== undefined) {
      if (req.sortBy) obj.sortKey = unifyKeyName(req.sortBy[0]);
      if (req.sortDesc[0]) obj.sortDir = "DESC";
      else obj.sortDir = "ASC";
    }
  }
  return obj;
}

function unifyKeyName(key: string | undefined) {
  if (!key) return null;
  return key.replace(/__.*$/, "");
}
