import Vue from "vue";
import Vuex from "vuex";
import * as str from "@/utils/utils-strings";

const storeModules: any = {};

// Dynamically attach vuex modules - all subfolders of "/src/store" folder
// https://webpack.js.org/guides/dependency-management/

const dirModules = require.context(
  "./", // Относительный путь до каталога компонентов
  true, // Обрабатывать или нет подкаталоги
  /^\.\/[\w- .]+\/index.ts$/ // index.ts in first nested level
);
dirModules.keys().forEach(modulePath => {
  // Получение конфигурации модуля
  const fileExports = dirModules(modulePath);
  const fileData = fileExports.default || fileExports;
  // extract names for module
  const moduleDirName = str.firstDir(modulePath);
  const moduleName = str.toPascalCase(moduleDirName);

  storeModules[moduleName] = fileData;
});

// COLLECTS storage modules from src/modules folder
const storesOfModules = require.context(
  "../modules", // Относительный путь до каталога компонентов
  true, // Обрабатывать или нет подкаталоги
  /^\.\/[\w- .]+\/store\/index.ts$/ // ./store/index.ts in first nested level
);
storesOfModules.keys().forEach(modulePath => {
  // Получение конфигурации модуля
  const fileExports = storesOfModules(modulePath);
  const fileData = fileExports.default || fileExports;
  // extract names for module
  const moduleDirName = str.firstDir(modulePath);
  const moduleName = str.toPascalCase(moduleDirName);

  storeModules[moduleName] = fileData;
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: storeModules,

  state: {},
  mutations: {},
  actions: {},
});
