import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import Store from "@/store/index";
import * as str from "@/utils/utils-strings";
import Home from "./modules/Home/index.vue";
import ModulesConfig from "@/modules/_cfg";
import config from "./config";

Vue.use(Router);
const routes: RouteConfig[] = [
  // manually configured routes can be placed here
  {
    path: "/",
    name: "Root",
    component: Home,
  },
];
// Dynamically attached routes - all subfolders in "/src/modules" folder
// https://webpack.js.org/guides/dependency-management/

// DIR MODULES ===
// modules in separate folders like: src/modules/{module-name}/index.vue
const dirModules = require.context(
  "./modules", // Относительный путь до каталога компонентов
  true, // Обрабатывать или нет подкаталоги
  /^\.\/[\w- .]+\/index.vue$/ // index.vue in first nested level
);
dirModules.keys().forEach(moduleFilePath => {
  // Получение конфигурации модуля
  const fileExports = dirModules(moduleFilePath);
  const fileData = fileExports.default || fileExports;
  const moduleDirName = str.dirname(moduleFilePath);
  const moduleConfig = ModulesConfig.find(c => c.dir === moduleDirName);
  const pathSuffix = moduleConfig && moduleConfig.pathSuffix ? "/" + moduleConfig.pathSuffix : "/:item?/:subitem?";
  const moduleName = moduleDirName.replace("--", "/");
  const moduleRoutePath = str.toKebabCase(moduleName);
  routes.push({
    name: moduleDirName,
    path: "/" + moduleRoutePath + pathSuffix,
    component: fileData,
  });
});
// ONE FILE MODULES ===
// single files in modules folder, like: src/modules/{module-name}.vue
const fileModules = require.context(
  "./modules", // Относительный путь до каталога компонентов
  false, // Не лезем в подкаталоги
  /\w+\.vue$/ // {module-name}.vue directly in /src/modules/
);

fileModules.keys().forEach(moduleFilePath => {
  // Получение конфигурации модуля
  const fileExports = fileModules(moduleFilePath);
  const fileData = fileExports.default || fileExports;
  const basename = str.basename(moduleFilePath);
  routes.push({
    name: basename,
    path: "/" + str.toKebabCase(basename) + "/:item?/:subitem?",
    component: fileData,
  });
});
// console.log(routes);
// ROUTER ===
const router = new Router({ routes });
// router.afterEach(checkAuthorization);
router.onReady(checkAuthorization);

export default router;

// HELPERS ===

function checkAuthorization() {
  const state: any = Store.state;
  const curRoute = router.currentRoute.path;

  if (state.App.user || window.userInfo) {
    if (curRoute === config.mainPath) return
    return router.push(config.mainPath);
  }

  // window.location.reload(); // пусть сервер разбирается куда редиректиться
  const path = window.location.origin + '/login';
  window.location.replace(path);
  // Store.dispatch("App/authorizeReadState");
}
