const http = {
  defaults: {
    method: "GET",
    cache: "no-store",
    // credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  },

  headers() {
    // do nothing
  },

  /**
   * Encodes data to be transferred as an url search-param
   * @param {*} obj any kind of data to send
   * @param {String} prefix service prefix for parent key name
   * @returns {String}
   */
  urlSerialize(obj: any, prefix = ""): string {
    const pairs: any[] = [];
    Object.keys(obj).forEach(key => {
      const val = obj[key];
      if (val === undefined || val === null) return;

      if (prefix) key = `${prefix}[${key}]`;
      if (val && typeof val === "object") {
        pairs.push(this.urlSerialize(val, key));
      } else {
        pairs.push(encodeURIComponent(key) + "=" + encodeURIComponent(val));
      }
    });

    return pairs.join("&");
  },

  send(url: string, data?: any, options: any = {}) {
    this.normalizeOptions(options);
    // format query data
    if (data) {
      if (options.method === "GET") {
        url += "?" + (typeof data === "string" ? data : this.urlSerialize(data));
      } else {
        options.body = data;
        if (data instanceof FormData) delete options.headers["Content-Type"];
      }
    }

    // return promise with http request
    return fetch(url, options).catch(error => {
      const err: any = new Error(error.message);
      err.name = "CONNECTION ERROR";
      throw err;
    });
  },

  get(url: string, data?: any, options?: any) {
    return this.send(url, data, { ...options, method: "GET" });
  },
  put(url: string, data?: any, options?: any) {
    return this.send(url, data, { ...options, method: "PUT" });
  },
  post(url: string, data?: any, options?: any) {
    return this.send(url, data, { ...options, method: "POST" });
  },
  patch(url: string, data?: any, options?: any) {
    return this.send(url, data, { ...options, method: "PATCH" });
  },
  delete(url: string, data?: any, options?: any) {
    return this.send(url, data, { ...options, method: "DELETE" });
  },

  // deep recursive merge with <target> priority
  normalizeOptions(target: any, defaults?: any): any {
    if (!defaults) defaults = this.defaults;
    Object.keys(defaults).forEach(key => {
      if (target[key] === undefined) {
        target[key] = defaults[key];
      } else if (typeof target[key] === "object") {
        target[key] = this.normalizeOptions(target[key], defaults[key]);
      }
    });
    return target;
  },
};

export default http;
