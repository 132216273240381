<template>
  <div class="object-card-view" :class="'depth' + _depth">
    <template v-if="title">
      <h2 v-if="_depth === 1">{{ title }}</h2>
      <h3 v-else-if="_depth === 2">{{ title }}</h3>
      <h4 v-else-if="_depth === 3">{{ title }}</h4>
      <div v-else>{{ title }}</div>
    </template>

    <template v-if="value === undefined"
      ><div class="is-null">Undefined</div></template
    >
    <template v-else-if="value === null"
      ><div class="is-null">Null</div></template
    >
    <template v-else-if="renderedList">
      <div :class="{ fieldforlog: oneLine === true }">
        <template v-for="(item, index) in renderedList">
          <template v-if="item.val && typeof item.val === 'object'">
            <object-card-view
              :value="item.val"
              :title="item.name || item.fullKey"
              :schema="schema"
              :options="options"
              :_depth="_depth + 1"
              :_prefix="item.fullKey"
              :key="item.fullKey"
            />
          </template>

          <template v-else-if="oneLine">
            <span :key="item.fullKey"
              ><em style="color: #fb8c00">{{ item.name || item.key }}:</em>
              {{ item.val
              }}<span v-if="renderedList.length - 1 !== index">, </span>
            </span>
          </template>

          <template v-else>
            <div
              :key="item.fullKey"
              class="field"
              :class="'field_' + item.fullKey"
              :data-name="item.fullKey"
            >
              <div class="name">
                {{ item.name || item.key }}:

                <div class="" v-if="isLastPing(item.fullKey)">
                  <small>
                    {{ isLastPing(item.fullKey) }}
                  </small>
                </div>
              </div>
              <span class="value" v-html="item.val"></span>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "object-card-view",

  props: {
    value: { type: [Object, Array, null], required: true }, // object to display
    title: { type: [String, Number], required: false }, // title of card
    schema: { type: Array, required: false }, // description, how to display each field
    options: { type: Object, required: false }, // common parameters of rendering {formatter(val,key), locales}
    oneLine: { type: Boolean, required: false, default: false }, // common parameters of rendering

    _depth: { type: Number, required: false, default: 1 }, // service param for rendering
    _prefix: { type: String, required: false, default: "" }, // service param for rendering

    brokenPilonItem: { type: Array, required: false }, // list of disconnected devices with last_ping_data
  },

  computed: {
    locales() {
      return (this.options && this.options.locales) || {};
    },
    formatter() {
      if (
        this.options &&
        this.options.formatter &&
        typeof this.options.formatter === "function"
      ) {
        return this.options.formatter;
      }
      return undefined;
    },
    renderedList() {
      if (!this.value) return null;
      const self = this;
      return Object.keys(this.value)
        .map(key => {
          let val = this.value[key];
          let fullKey = key;
          if (self._prefix) {
            fullKey = Array.isArray(this.value)
              ? `${self._prefix}[${key}]`
              : `${self._prefix}.${key}`;
          }
          const name = self.locales[fullKey] || self.locales[key];

          val =
            typeof val === "object" && val
              ? val
              : self.getValueString(val, fullKey);
          return { key, fullKey, name, val };
        })
        .filter(r => r.val !== "_norender");
    },
  },
  watch: {
    brokenPilonItem() {
      this.isLastPing();
    },
    renderedList() {
      this.isLastPing();
    },
  },

  methods: {
    getLocale(txt, prefix = "") {
      return this.locales[prefix + txt] || this.locales[txt] || txt;
    },
    getKeyString(txt, prefix = "") {
      return this.getLocale(txt, prefix);
    },
    getValueString(val, key) {
      val = this.formatter ? this.formatter.call(this, val, key) : String(val);
      return this.getLocale(val);
    },
    isLastPing(keyName = "") {
      let date = null;
      this.brokenPilonItem.forEach(item => {
        if (keyName === Object.keys(item)[0]) {
          date = item[keyName];
        }
      });
      return date
        ? `Последние данные за ${moment(date).format("HH:mm, DD MMM YYYY")}`
        : null;
    },
  },
};
</script>

<style>
.object-card-view {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 3px;
  margin-bottom: 10px;
}

.object-card-view .field:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.object-card-view .fieldforlog:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.object-card-view .item {
  white-space: nowrap;
}
.object-card-view .name {
  display: inline-block;
  padding: 5px;
  width: 29%;
}
.object-card-view .value {
  display: inline-block;
  padding: 5px;
  width: 70%;
}
</style>
