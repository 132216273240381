
export default {

  schema: null,
  trClass: null, // string|function
  groupBy: null,

  rowList(records){
    if(!records) return null;
    if(!records.length) return [];

    return records.map(this.formatRow.bind(this));
  },

  group(rows, keyname, schema){
    if(!rows) return null;
    if(!rows.length) return [];

    // if no keyname defined to group by it, make one group
    // without name, containing all records
    if(!keyname){ return [{ _groupName: null, _rows: rows }]; }

    let groupMap = {};
    const keyindex = schema.findIndex(cell => cell.key === keyname);
    if(keyindex === -1) throw new Error(`Keyname "${keyname}" not found in row`);

    // put into groupMap rows
    rows.forEach(rowData => {
      const cell = rowData.cells[keyindex];
      // Make name as a string !!NOT INTEGER, otherwise sorting will be destroyed.
      // With space at the end of string it can not be casted to integer
      const _groupName = String(cell.renderedValue) + ' ';
      if(!groupMap[_groupName]) groupMap[_groupName] = {_groupName, _rows:[]};
      groupMap[_groupName]._rows.push(rowData);
    })

    return Object.values(groupMap);
  },

  formatCell(record, cellSchema){
    const keyname = cellSchema.key;
    const value = this.cellValue(record, keyname);
    const renderedValue = this.renderValue(cellSchema, value, record);
    let cssClass;
    if(typeof cellSchema.cssClass === 'function'){
      cssClass = cellSchema.cssClass.call(null, value, record, keyname);
    } else if(cellSchema.cssClass) {
      cssClass = String(cellSchema.cssClass);
    } else {
      cssClass = '';
    }

    return {renderedValue, cssClass, value, record, schema: cellSchema}
  },

  renderValue(cellSchema, value, record){
    const keyname = cellSchema.key;
    let renderedValue;
    if(typeof cellSchema.renderer === 'function'){
      renderedValue = cellSchema.renderer.call(null, value, record, keyname);
    } else {
      if(value === null){
        // renderedValue = '<span class="null-value">null</span>';
        renderedValue = null;
      } else if(value === undefined){
        renderedValue = undefined;
        // renderedValue = '<span class="undefined-value">undefined</span>';
      } else if (typeof value === 'object') {
        renderedValue = '<pre>'+JSON.stringify(value, null, '  ')+'</pre>';
      } else {
        renderedValue = String(value);
      }
    }
    return renderedValue;
  },

  formatRow(record, rowIndex){
    if(!this.schema) throw new Error('Schema is not defined');
    let cssClass;
    if(typeof this.cssClass === 'function'){
      cssClass = this.cssClass.call(null, record, rowIndex);
    } else if(this.cssClass) {
      cssClass = String(this.cssClass);
    } else {
      cssClass = '';
    }
    const cells = this.schema.map((cellSchema, i) => {
      const cellData = this.formatCell(record, cellSchema);
      cellData.cellIndex = i;
      cellData.rowIndex = rowIndex;
      return cellData;
    })

    return {rowIndex, cssClass, cells, record};
  },

  /** extracts value from record when record is nested,
   *  keyname has dot notation, for example: "user.address.street".
   *  if no keyname found, then returns whole branch
   * @param {Object} record
   * @param {String} keyname
   * @returns {Any} */
  cellValue(record, keyname) {
    if(keyname === '.') return record;
    // no dots in keyname
    if(!/\./.test(keyname)){ return record[keyname] === undefined ? record : record[keyname]; }
    // not nested data, just keyname with dots: {'strange.key': 'wow'}
    if(record[keyname] !== undefined) return  record[keyname];

    // nested data, try to go deep
    const keyChunks = keyname.split('.');
    let brunch = record;
    for(let i=0; i<keyChunks.length; i++){
      const key = keyChunks[i];
      brunch = brunch[key];
      if(brunch === undefined) return record;
    }
    return brunch;
  },

  cellName(keyname){
    let name = keyname.replace(/\W/g, ' '); // remove non-letters
    name = name.replace(/([a-z0-9])([A-Z])/g, '$1 $2'); // "camelCase" to "camel Case"
    name = name.substr(0, 1).toUpperCase() + name.substr(1); // capitalize first letter
    return name;
  },

  idName(keyname){ return keyname.replace(/\W/g, '-'); },

  stringify(value){ return JSON.stringify(value, null, ' '); },

  createSchema(record){
    if(!record) return null;
    return this.recordSchema(Object.keys(record));
  },

  /** normalizes cols schema */
  recordSchema(schema){
    // if schema elements are strings convert it to objects
    schema = schema.map(key => (typeof key === 'string')? {key} : key );

    // normalize
    schema = schema.map((cellSchema, i) => {
      if(!cellSchema.name) cellSchema.name = this.cellName(cellSchema.key);
      cellSchema.colIndex = i;
      return cellSchema;
    } );

    return schema;
  },
}
