<template>

  <td
    :class="cssClass"
    @click="emitClick"
    v-html="value"
  />

</template>

<script>
export default {

  props: {
    cell:    { type: Object },
  },

  computed: {
    cssClass(){
      let css = this.cell.cssClass;
      css += ' td-key_' + this.cell.schema.key.replace(/\W/, '-');
      return css.trim();
    },
    value(){
      if(this.cell.renderedValue === null) return "<span class='is-null'>NULL</span>";
      if(this.cell.renderedValue === undefined) return "<span class='is-undefined'>undefined</span>";
      return this.cell.renderedValue;
    }
  },

  methods: {
    emitClick(){ this.$emit('cellClick', this.cell); },
  }
};
</script>
