













import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DraweredBlock extends Vue {
  @Prop({ type: Boolean, default: true }) public open!: boolean;
  @Prop({ type: String, default: "30vw" }) public drawerSize!: string;

  localOpen = true;

  beforeMount(): void {
    this.assignData();
  }
  @Watch("open") onOpenChange() {
    this.assignData();
  }
  assignData() {
    this.localOpen = this.open;
  }

  get sizeIsPercentage() {
    return this.drawerSize.trim().substr(-1) === "%";
  }

  get drawerStyle() {
    return this.localOpen ? { width: this.drawerSize } : { width: 0 };
  }
  get sliderStyle() {
    if (this.sizeIsPercentage) return { width: `calc(100% - 10px)` };
    return { width: `calc(${this.drawerSize} - 10px)` };
  }

  toggle() {
    this.localOpen = !this.localOpen;
    this.$emit("input", this.localOpen);
    this.localOpen ? this.$emit("open") : this.$emit("close");
  }
}
