const isDevelop = process.env.NODE_ENV !== "production";

// CONFIG DEFAULT (PRODUCTION) ===
const config = {
  mainPath: "/admin/users",
  apiUrl: "/",
  watchSnapShotTimer: 0,
};

// DEVELOP REASSIGN ===
if (isDevelop) {
  // do something
  // config.apiUrl = "/kiaskfarm-proxy/";
  config.apiUrl = "/kiaskfarm-proxy-local/";
}

export { config as default };
