


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import rest from "@/utils/rest";

function defaultOnLoad(response: any): any {
  return response.content;
}

@Component({
  components: {},
})
export default class ModelSearch extends Vue {
  @Prop() public value!: any;
  @Prop({ type: String }) public url!: string;
  @Prop({ type: String }) public valueUrl!: string; // урл по которому запрашивается список по id (value)
  @Prop({ type: Function, default: defaultOnLoad }) public onLoad!: (response: any) => any;
  @Prop({ type: Function, default: defaultOnLoad }) public onValueLoad!: (response: any) => any;
  @Prop({ type: String, default: "name" }) public nameIs!: string;
  @Prop({ type: [String, Array, Function], default: null }) public valueIs!: any;

  public inheritAttrs = false;
  private limit = 20;
  private list: any[] = [];
  private isLoading = false;
  private search: any = null;
  private error: any = null;
  private localValue: any = null;

  private beforeMount() {
    this.localValue = this.value;
    if (this.value) this.loadByValue();
  }

  get isObject() {
    return this.valueIs === null;
  }

  private onInput() {
    this.$emit("input", this.localValue);
    this.$emit(
      "data-change",
      this.list.find(e => e[this.valueIs] === this.localValue)
    );
  }

  private loadBySearch() {
    this.error = "";
    this.isLoading = true;
    const url = this.url.replace(/%s/g, this.search);
    rest
      .get(url)
      .then((res: any) => {
        let list = this.onLoad ? this.onLoad(res) : res;
        if (!list) list = [];
        if (this.localValue && !list.length) list = [{ name: this.localValue }];
        this.list = list;
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private loadByValue() {
    if (!this.valueUrl) return;
    this.error = "";
    this.isLoading = true;
    const url = this.valueUrl.replace(/%s/g, this.localValue);
    rest
      .get(url)
      .then((res: any) => {
        let list = this.onValueLoad ? this.onValueLoad(res) : res; // это идиотизм
        if (!list || !list.length) list = [{ name: this.localValue }];
        this.list = list;
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch("search")
  private onSearchChange() {
    if (this.search == null) return;
    if (this.isLoading) return;
    this.loadBySearch();
  }
  @Watch("value")
  private onValueChange() {
    if (!this.value) {
      this.search = null;
      this.localValue = null;
      return;
    }
    this.localValue = this.value;
    this.loadByValue();
  }
}
