























// app common
import { Component } from "vue-property-decorator";
import CommonModule from "@/commons/includes/common-module";

import users from "./views/users/index.vue";
import clients from "./views/clients/index.vue";
import roles from "./views/roles/index.vue";

@Component({
  components: {
    users,
    clients,
    roles,
  },
})
export default class Admin extends CommonModule {
  public defaultAsset = "users";
}
