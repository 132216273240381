/**
 * Конфиг модулей, который собирается в единый json автоматически
 * после сканирования папки src/modules/
 * из отдельных файлов /src/modules/{{ModuleName}}/module.json
 */
import { toKebabCase, dirname } from "@/utils/utils-strings";
const ModulesConfig: any[] = [];

const requireModules = require.context(
  "./", // Относительный путь до каталога компонентов
  true, // Обрабатывать или нет подкаталоги
  /module.json$/ // Регулярное выражение для определения файлов базовых компонентов
);

requireModules.keys().forEach(path => {
  // Получение конфигурации модуля
  const webpackFileConfig = requireModules(path);

  const moduleData = webpackFileConfig.default || webpackFileConfig;
  // moduleData.path = path;
  moduleData.dir = dirname(path);
  moduleData.id = toKebabCase(trimDir(moduleData.dir));

  ModulesConfig.push(moduleData);
});

function trimDir(str: string) {
  str = str.replace(/^\.\//, "");
  str = str.replace(/\/$/, "");
  return str;
}

export default ModulesConfig.filter(e => !e.disabled);
