













import { Component, Vue } from "vue-property-decorator";
import ModulesConfig from "@/modules/_cfg";

@Component
export default class AppLeftMenu extends Vue {
  // @Prop() private msg!: string;

  get trimmedUrl() {
    return this.trimSlashes(this.$route.path);
  }
  get moduleName() {
    return this.trimmedUrl.split("/")[0];
  }
  get moduleCfg() {
    // find config for current module and take menu description
    return ModulesConfig.find(m => m.id === this.moduleName);
  }
  get items(): App.MenuItem[] {
    if (!this.moduleCfg) return [];
    // find config for current module and take menu description
    return this.moduleCfg.menu;
  }
  get moduleIcon() {
    if (!this.moduleCfg) return null;
    return this.moduleCfg.icon;
  }

  // private isActive(item: App.MenuItem): boolean {
  //   return this.trimSlashes(item.path) === this.trimmedUrl;
  // }

  private trimSlashes(path: string) {
    return path.replace(/^\//, "").replace(/\/$/, "");
  }

  private getIcon(icon: string) {
    if (icon) return icon;
    if (this.moduleIcon) return this.moduleIcon;
    return "file";
  }
}
