/**
 * Commons - компоненты, настроенные на это конкретное приложение,
 * например, app-layout, app-menu,
 * которые используются везде или во многих других компонентах
 */

import Vue from "vue";

// Require in a base component context
const Widgets = require.context("./", false, /.*\.vue$/);
Widgets.keys().forEach(fileName => {
  const fName = fileName.slice(2, -4);
  const componentConfig = Widgets(fileName);
  Vue.component(fName, componentConfig.default || componentConfig);
});
