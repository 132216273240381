























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SaveIndicator extends Vue {
  @Prop({ type: [Boolean], default: false }) public pending!: boolean;
  @Prop({ type: [Boolean], default: false }) public changed!: boolean;

  protected savedTimeout: number | null = null;
  protected saved = false;

  @Watch('changed')
  onChangedChange(newVal: any, oldVal: any) {//debugger
    // изменилось с "изменено" на "не изменено", наверное, записали данные на сервер
    if (newVal === false && oldVal === true) {
      // if(this.pending) throw new Error('В SaveIndicator changed изменилось на false, при этом pending тоже осталось true. Это ошибка логики');

      if(this.savedTimeout) return; // не светим, если он уже светится
      this.saved = true;
      this.savedTimeout = setTimeout(() => {
        this.saved = false;
        this.savedTimeout = null;
      }, 2000);
    }
  }
}
