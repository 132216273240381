<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" >
    <path class="svg-checkbox-contour" d="M20,22H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v16C22,21.1,21.1,22,20,22z"/>
    <polyline class="svg-checkbox-tick" points="6.6,12.7 10.5,17.2 18.7,5.9 "/>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss">
  .svg-checkbox-contour{
    fill:none;
    stroke:#ccc;
    stroke-miterlimit:10;
  }
  .svg-checkbox-tick{
    fill:none;
    stroke:#555;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
    stroke-width: 2px;
    transition: opacity .1s, transform .1s;
    opacity: 0;
    transform: translateY(10px);
  }

  .selected{
    .svg-checkbox-tick{
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>
