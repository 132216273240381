var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-3 cluster-block"},[_c('v-menu',{attrs:{"left":"","nudge-left":_vm.nudgeLeft,"content-class":"cluster-block__menu","z-index":"","close-on-content-click":false,"offset-y":"","transition":"slide-y-transition","max-width":"286"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"#0000004d"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-view-grid")])],1)],1)]}}])},[(_vm.clusterListLength > 1)?[_vm._l((_vm.clusterList),function(cluster){return _c('v-hover',{key:cluster.name,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-1 card-block",style:({
              background: _vm.getCardColor(cluster.bg_color),
              transition: '0.1s box-shadow',
            }),attrs:{"elevation":hover ? 6 : 0,"width":"84","height":"84"},on:{"mouseenter":function($event){_vm.displayFullText = true},"mouseleave":function($event){_vm.displayFullText = false},"click":function($event){return _vm.clickAction(cluster)}}},[_c('v-card-text',{staticClass:"card-block__content"},[_c('div',{},[_c('v-icon',[_vm._v("mdi-"+_vm._s(cluster.icon))])],1),_c('div',{staticClass:"text--primary card-block__content__text"},[_vm._v(" "+_vm._s(cluster.name)+" ")])])],1)]}}],null,true)})}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-1",staticStyle:{"background":"#cfd8dc"},attrs:{"href":"/","elevation":hover ? 6 : 0,"width":"100%","height":"40"}},[_c('v-card-text',{staticClass:"card-block__content"},[_c('div',{},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black"}},[_vm._v("mdi-view-grid")]),_c('span',{staticStyle:{"color":"black"}},[_vm._v("На главную")])],1)])],1)]}}],null,false,1024196529)})]:(_vm.clusterListLength === 1)?[_vm._v(" Нет других доступных приложений")]:[_vm._v("Нет доступных приложений ")]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }