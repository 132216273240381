




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

type DRange = [number, number] | [string, string] | [number] | [string] | [];

@Component({
  components: {},
})
export default class DatePicker extends Vue {
  @Prop({
    type: [Array],
    default() {
      return [];
    },
  })
  public value!: DRange;
  // treat date as a timestamp
  @Prop({ type: [Boolean], default: false }) public isTimestamp!: boolean;

  public inheritAttrs = false;
  private showCalendar = false;
  private localDate: any = "";

  private beforeMount() {
    this.assignValue();
  }
  @Watch("value")
  private onValueChange() {
    this.assignValue();
  }
  private assignValue() {
    if (this.value) {
      this.localDate = this.encodeValueToLocal(this.value);
    } else {
      this.localDate = "";
    }
  }
  private encodeValueToLocal(range: DRange) {
    if (!range) return "";
    if (this.isTimestamp) {
      return moment(range[0]).format("YYYY-MM-DD");
    } else {
      return range[0];
    }
  }

  private prev() {
    this.localDate = moment(this.localDate).subtract(1, "d");
    this.onInput();
  }
  private next() {
    this.localDate = moment(this.localDate).add(1, "d");
    this.onInput();
  }
  get disabledIcon(): boolean {
    return this.dateNow <= this.localDate;
  }
  get rangeStr(): string {
    const date = this.localDate;
    if (!date) return "";

    const start = moment(date);
    return `${start.format("D MMM")}`;
  }
  get dateNow() {
    return moment().format("YYYY-MM-DD");
  }

  private onInput() {
    if (!this.localDate) {
      this.$emit("input", null);
      return;
    }
    this.closeIfFilled();

    this.$emit("input", this.decodeLocalToValue(this.localDate));
  }
  private closeIfFilled() {
    if (!this.localDate) return;
    setTimeout(() => (this.showCalendar = false), 500);
  }
  private decodeLocalToValue(date: string): DRange {
    if (!date) return [];
    if (this.isTimestamp) {
      return [moment(date).startOf("day").valueOf(), moment(date).endOf("day").valueOf()];
    } else {
      return [date, date];
    }
  }
}
