






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
// import rest from "@/utils/rest";
// import { clone, toWritable } from "@/utils/utils-obj";

@Component({
  components: {},
})
export default class DateInput extends Vue {
  @Prop({ type: [String] }) public value!: string | null;

  private showCalendar = false;
  private localValue: string | null = null;

  beforeMount() {
    this.localValue = this.value;
  }
  @Watch("value")
  onValueChange() {
    this.localValue = this.value;
  }

  private onInput(val: string | undefined) {
    this.showCalendar = false;
    this.$emit("input", val);
  }
  get displayDate() {
    if (!this.localValue) return "";
    return moment(this.localValue).format("DD MMM YYYY");
  }
}
