



























import { Component, Prop, Vue } from "vue-property-decorator";
import { clone } from "@/utils/utils-obj";

@Component({
  components: {},
})
export default class AdminToolsToolbar extends Vue {
  @Prop({ type: [Number] }) public length!: number;
  private localFilter: any = {};

  private created() {
    this.clearlocalFilter();
  }

  private clearlocalFilter() {
    const localFilter = {};
    this.localFilter = localFilter;
  }

  private emitPage(val: any) {
    this.$emit("change-page", val);
  }

  private emitFilter() {
    this.$emit("filter", clone(this.localFilter));
  }
}
