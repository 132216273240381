import { Component, Vue } from "vue-property-decorator";
import modulesConfig from "@/modules/_cfg";
import { toPascalCase } from "@/utils/utils-strings";
// import rest from "@/utils/rest";

/**
 * Базовый класс для корневой страницы модуля
 * Пример использования:
 * import CommonModule from '@/commons/includes/common-module';
 * export default class %MyModule extends CommonModule {
 */
@Component({ components: {} })
export default class CommonModule extends Vue {
  protected defaultAsset = "";

  protected beforeMount() {
    if (this.defaultAsset && !this.activeAsset) this.openAsset(this.defaultAsset);

    // const logParams = {
    //   target_name: "app." + this.moduleId,
    //   action: "viewOpen",
    //   target_id: null,
    //   payload: null,
    // };

    // rest.post(`user-action-log`, logParams);
  }

  get moduleList() {
    return modulesConfig;
  }

  get moduleId() {
    const rootDir = this.$route.path.replace(/^\//, "").replace(/\/.+$/, "");
    return rootDir;
  }

  get moduleConfig() {
    return modulesConfig.find(e => e.id === this.moduleId);
  }

  get state(): any {
    return this.$store.state[toPascalCase(this.moduleId)];
  }

  get authUser() {
    // if (!this.$store.state.App.isAuthorized) return null;
    return this.$store.state.App.user;
  }

  get moduleIsAllowedForUser() {
    if (!this.authUser) return null;
    if (!this.moduleConfig.roles) return true;
    if (!this.authUser.roles || !this.authUser.roles.length) return false;
    return this.$hasOneOfRoles(this.moduleConfig.roles);
  }

  get activeAsset(): string | null | undefined {
    return this.$route.params.asset;
  }

  get activeId(): string | number | null {
    const id = this.$route.params.id;
    if (id === undefined) return null;
    return /^\d+$/.test(id) ? Number(id) : id;
  }

  get viewName(): string {
    if (this.activeAsset) return this.activeAsset;
    if (this.defaultAsset) return this.defaultAsset;
    return "view-is-undefined";
  }

  protected openAsset(asset: string) {
    this.$go("/" + this.moduleId + "/" + asset);
  }
  protected closeAsset() {
    this.$go("/" + this.moduleId);
  }
}
