<template>
  <select :placeholder="placeholder" @change="onChange">
    <option value="">{{noSelectedText}}</option>
    <option
      v-for="(opt,i) in normOptions"
      :key="'option_'+i"
      :selected="value === opt.value"
      :value = "i"
    >
      {{opt.name}}
    </option>
  </select>
</template>

<script>
export default {
  name: 'FilterSelector',

  props: {
    placeholder:    {type: String, default: 'фильтр'},
    noSelectedText: {type: String, default: 'все'},
    options:        {type: Array,  default(){ return []; }},
    value:          {},
  },

  beforeMount(){ this.assignData(); },
  watch: {
    value(){ this.assignData(); }
  },

  data(){ return {
    localValue: null
  }; },

  computed: {
    normOptions(){
      if(!(this.options instanceof Array)) throw new Error(`Options must be an array, ${typeof this.options} given.`);
      return this.options.map(o => {
        if(o && o.name && o.value !== undefined) return o;
        return {
          name: String(o),
          value: o,
        }
      })
    }
  },

  methods: {
    clone(data){ return JSON.parse(JSON.stringify(data)); },
    assignData(){ this.localValue = this.clone( this.value ); },
    onChange(e){
      const index = e.target.value;
      const value = index === '' ? undefined : this.normOptions[index].value
      this.$emit('input', value);
    }
  }
}
</script>

<style>

</style>
