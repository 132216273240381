


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
// import rest from "@/utils/rest";
// import { clone, toWritable } from "@/utils/utils-obj";

type DRange = [number, number] | [string, string] | [number] | [string] | [];

@Component({
  components: {},
})
export default class DateRange extends Vue {
  @Prop({
    type: [Array],
    default() {
      return [];
    },
  })
  public value!: DRange;
  // treat date as a timestamp
  @Prop({ type: [Boolean], default: false }) public isTimestamp!: boolean;

  public inheritAttrs = false;
  private showCalendar = false;
  private localRange: DRange = [];

  private beforeMount() {
    this.assignValue();
  }
  @Watch("value")
  private onValueChange() {
    this.assignValue();
  }
  private assignValue() {
    if (this.value) {
      this.localRange = this.encodeValueToLocal(this.value);
    } else {
      this.localRange = [];
    }
  }
  private encodeValueToLocal(range: DRange): DRange {
    if (!range) return [];
    if (this.isTimestamp) {
      return (range as number[]).map(d => moment(d).format("YYYY-MM-DD")) as DRange;
    } else {
      return range;
    }
  }
  private decodeLocalToValue(range: DRange): DRange {
    if (!range) return [];
    if (!range.length) return [];
    if (this.isTimestamp) {
      return (range as string[]).map((s, i) => {
        const d = moment(s);
        !i ? d.startOf("day") : d.endOf("day");
        return d.valueOf();
      }) as DRange;
    } else {
      return range;
    }
  }

  get rangeStr(): string {
    const range = this.localRange as any[];
    if (!range) return "";
    if (!range.length) return "";

    const start = moment(range[0]);
    if (range.length === 1) return `c ${start.format("D MMM")}`;

    const end = moment(range[1]);
    return `${start.format("D MMM")} - ${end.format("D MMM")}`;
  }

  get adviceStr(): string {
    const range = this.localRange;
    if (!range) return "";
    if (!range.length) return "Укажите начало (включительно)";
    if (range.length === 1) return "Окончание (не включая)";

    const start = moment(range[0]);
    const end = moment(range[1]);
    const dur = moment.duration(end.valueOf() - start.valueOf());
    return "Выбрано: " + dur.humanize();
  }

  private onInput() {
    const range = this.localRange;
    if (!range) {
      this.$emit("input", null);
      return;
    }
    if (range.length !== 2) return;
    this.closeIfFilled();
    this.checkOrder();

    this.$emit("input", this.decodeLocalToValue(this.localRange));
  }

  private closeIfFilled() {
    if (!this.localRange) return;
    if (this.localRange.length < 2) return;
    setTimeout(() => (this.showCalendar = false), 500);
  }

  private checkOrder() {
    if (!this.localRange) return;
    if (this.localRange.length < 2) return;
    if (moment(this.localRange[0]).valueOf() > moment(this.localRange[1]).valueOf()) {
      this.localRange = [this.localRange[1], this.localRange[0]] as DRange;
    }
  }
}
