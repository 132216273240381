/**
 * Виджеты - независимые компоненты не завязанные на VUEX и конкретное приложение
 * все виджеты .vue-файлы подключаются автоматически глобально в этом файле
 * если компонент лежит в подпапке и имеет сложную структуру, надо подключать вручную
 */

import Vue from "vue";

// WIDGETS in src/widgets folder
// Require in a base component context
const Widgets = require.context("./", false, /.*\.vue$/);
Widgets.keys().forEach(fileName => {
  const fName = fileName.slice(2, -4);
  const componentConfig = Widgets(fileName);
  Vue.component(fName, componentConfig.default || componentConfig);
});

// complex widgets placed in separate folders
// import MkTable from "./widgets/table-view-2/index.vue";
// Vue.component("MkTable", MkTable);
// import mkGrid from "./widgets/table-view-2/mk-grid.vue";
// Vue.component("mkGrid", mkGrid);
