






































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AppUser extends Vue {
  mounted() {
    this.$nextTick(() => {
      if (this.$userInfo) {
      this.$store.commit("App/SET", { user: this.$userInfo });
      window.userInfo = undefined;
    }
    });
  }
  get user(): App.User {
    return this.$store.state.App.user;
  }
  get name() {
    if (!this.user) return "Not Set";
    return `${this.user.familyName} ${this.user.givenName} ${this.user.middleName}`;
  }
  get abr() {
    const u = this.user;
    if (!u) return "NS";
    const inits = [u.familyName, u.givenName].map(e => String(e).trim().substr(0, 1).toUpperCase()).join("");
    return inits || "?";
  }
  get initials() {
    return this.abr;
  }

  public logout() {
    this.$store.commit("App/SET", { user: null });
    // const path = window.location.href.replace(/#.+/, "login")
    // rest.post("logout").finally(() => {
    //   window.location.href = path;
    // });
    // return false;
    window.location.href = window.location.href.replace(/#.+/, "logout")
  }
}
