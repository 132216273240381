








































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { clone } from "@/utils/utils-obj";
import rest from "@/utils/rest";

interface LocalUser extends App.User {
  password: string | null;
  repeatedPassword?: string | null;
}

@Component({
  components: {},
})
export default class AdminInfoForm extends Vue {
  @Prop({ type: [Number, String] }) public activeId!: string | number;
  @Prop({ type: [Object] }) public item!: any;
  private localItem: LocalUser | null = null;
  private isPending = false;
  private error: any = null;
  private newPass = false;
  private show1 = false;
  private show2 = false;
  private rules = {
    required: (v: any) => !!v || "Поле обязательно для заполнения",
    min: (v: any) => (v && v.length >= 5) || "Пароль не может быть менее 5 символов",
    email: (v: any) => /.+@.+\..+/.test(v) || "Не корректный E-mail",
  };
  private gender = [
    {
      name: "Мужской",
      sysName: 1,
    },
    {
      name: "Женский",
      sysName: 2,
    },
  ];

  private beforeMount() {
    this.assignData();
  }

  @Watch("activeId")
  private onIdChange() {
    this.newPass = false;
    this.assignData();
  }
  @Watch("item")
  private onItemChange() {
    this.newPass = false;
    this.assignData();
  }
  @Watch("newPass")
  private onNewPassChange() {
    if (this.localItem && !this.newPass) this.localItem.password = this.localItem.repeatedPassword = null;
  }

  get mode() {
    if (typeof this.activeId === "number") return "edit";
    if (this.activeId === "_new") return "create";
    return null;
  }
  get createIsAvailable() {
    if (!this.localItem) return false;
    const it = this.localItem;
    if (
      !this.isSet(it.username) ||
      !this.isSet(it.password) ||
      it.password !== it.repeatedPassword ||
      !this.isSet(it.given_name) ||
      !this.isSet(it.family_name) ||
      !this.isSet(it.middle_name) ||
      !this.isSet(it.email) ||
      !this.isSet(it.gender)
    )
      return false;
    return true;
  }
  get editIsAvailable() {
    if (!this.localItem) return false;
    const it = this.localItem;
    if (!this.isSet(it.given_name) || !this.isSet(it.family_name) || !this.isSet(it.middle_name) || !this.isSet(it.email) || !this.isSet(it.gender))
      return false;
    if (this.newPass) if (!this.isSet(it.password) || it.password !== it.repeatedPassword) return false;

    return true;
  }

  private isSet(val: any) {
    return val !== null && val !== undefined && val !== "";
  }
  private getBlankItem() {
    return {
      id: null,
      given_name: null,
      family_name: null,
      middle_name: null,
      email: null,
      username: null,
      password: null,
      gender: null,
      roles: [],
    };
  }

  private assignData() {
    this.error = "";
    this.isPending = false;
    this.localItem = this.item ? clone(this.item) : this.getBlankItem();
  }

  private createUser() {
    this.isPending = true;
    this.localItem ? (this.localItem = parseInputObj(this.localItem)) : (this.localItem = null);
    this.error = "";
    if (!this.localItem) return;
    const params = {
      username: this.localItem.username,
      password: this.localItem.password,
      given_name: this.localItem.given_name,
      family_name: this.localItem.family_name,
      middle_name: this.localItem.middle_name,
      email: this.localItem.email,
      gender: this.localItem.gender,
      roles: this.localItem.roles,
    };

    rest
      .post(`v1/user`, params)
      .then(() => {
        this.$mktoast.success("Пользователь добавлен");
        this.$emit("item-create", this.localItem);
        this.assignData();
        this.$go("/admin/users");
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isPending = false;
      });
  }
  private updateUser() {
    this.isPending = true;
    this.localItem ? (this.localItem = parseInputObj(this.localItem)) : (this.localItem = null);
    this.error = "";
    if (!this.localItem) return;
    const params = {
      id: this.localItem.id,
      password: this.localItem.password,
      given_name: this.localItem.given_name,
      family_name: this.localItem.family_name,
      middle_name: this.localItem.middle_name,
      email: this.localItem.email,
      gender: this.localItem.gender,
      roles: this.localItem.roles,
    };

    rest
      .put(`v1/user`, params)
      .then(() => {
        this.$mktoast.success("Пользователь изменен");
        this.$emit("item-update", this.localItem);
        this.assignData();
        this.$go("/admin/users");
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isPending = false;
      });
  }
  // private deleteUser() {
  //   this.isPending = true;
  //   this.error = "";

  //   if (confirm("Вы точно хотите удалить пользователя?")) {
  //     rest
  //       .delete(`users/${this.activeId}`)
  //       .then(() => {
  //         this.$mktoast.success("Пользователь успешно удален");
  //         this.$emit("item-update");
  //         this.$go("/admin/users");
  //       })
  //       .catch(e => {
  //         this.$mktoast.danger(e.message, e.name);
  //         this.error = e.message;
  //       })
  //       .finally(() => {
  //         this.isPending = false;
  //       });
  //   }
  // }
}

function parseInput(val: any): string {
  if (!val) return val;
  if (typeof val !== "string") return val;
  return val.replace(/<.*?>/gim, " ").trim();
}

function parseInputObj(obj: any) {
  const res: any = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === "") res[key] = null;
    else res[key] = parseInput(obj[key]);
  });
  return res;
}
