import { Component, Vue, Watch } from "vue-property-decorator";
import { closeItem, openItem, tblOptionsToRequestParams } from "./helpers";

import { Identified } from "./types";
// types
import { objIsEmpty } from "@/utils/utils-obj";
import rest from "@/utils/rest";
// import moment from "moment";
import vue from "vue";

/**
 * Базовый класс для компонентов со списками
 * Пример использования:
 * import CommonList from '@/commons/includes/common-list';
 * export default class MyList extends CommonList<MyItem> {
 */
@Component({ components: {} })
export default class CommonList<T extends Identified> extends Vue {
  protected list: T[] | null = null;
  protected selected: T[] = [];
  protected idName = "id";
  protected totalRowsCount: number | null = null;
  protected error = "";
  protected isLoading = false;
  /** config for table render */
  protected tblHeaders: Vuetify.Header[] = [
    { value: "id", text: "Идентификатор" },
    { value: "name", text: "Название" },
  ];
  /** config for table pagination, sorting */
  protected tblOptions: Vuetify.TblOptions = {
    page: 0,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };
  /** filters for server request */
  protected apiFilters: any = {};

  protected beforeMount() {
    this.readList();
  }
  @Watch("tblOptions.itemsPerPage")
  protected onItemsPerPageChange() {
    this.readList();
  }
  @Watch("tblOptions.page")
  protected onPageChange() {
    this.readList();
  }
  @Watch("apiFilters")
  protected onFiltersChange() {
    if (this.tblOptions.page === 0) {
      this.readList();
    } else {
      this.tblOptions.page = 0; // change of page will invoke readList();
    }
  }

  /**  mapper function for list elements (calculate additional data to show in table) */
  protected listMapper(listItem: any) {
    return listItem;
  }
  /** mapped (extended/processed) list */
  get xList(): null | any[] {
    return this.list ? this.list.map((e: any) => this.listMapper(e)) : null;
  }

  get activeAsset(): string {
    return this.$route.params.asset;
  }
  get activeId(): null | string | number {
    const strId = this.$route.params.id;
    if (strId === undefined) return null;
    const numId = Number(strId) - 0;
    return isNaN(numId) ? strId : numId;
  }

  /** list request endpoint */
  protected apiUrl(): string {
    return `${this.activeAsset}`;
  }
  protected apiUrlCount() {
    return '';
  }
  /** params for list request */
  protected apiParams(userParams: any = {}): any {
    let params;
    if (!this.apiUrlCount()) params = Object.assign({}, this.apiFilters, userParams);
    else params = Object.assign({}, tblOptionsToRequestParams(this.tblOptions), this.apiFilters, userParams);
    return objIsEmpty(params) ? undefined : params;
  }

  protected async readList() {
    this.error = "";
    this.isLoading = true;

    if (this.apiUrlCount()) {
      await rest
        .get(this.apiUrlCount())
        .then(res => {
          this.totalRowsCount = res;
        })
    }
    rest
      .get(this.apiUrl(), this.apiParams())
      .then(res => {
        this.list = res;
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => (this.isLoading = false));
  }
  /** add item at the start of local list (when record created on server) */
  protected prependList(item: any) {
    if (!this.list) this.list = [];
    this.list.unshift(item);
  }
  /** update item in local list (when record updated on server) */
  protected updateItemInList(item: any) {
    if (!this.list) return;
    const idx = this.list.findIndex(e => e.id === item.id);
    vue.set(this.list, idx, item);
  }

  /** adds "/{id}" to path and pushes it */
  protected openItem(itemOrId: any) {
    return openItem.call(this, itemOrId);
  }
  /** removes last path dirname "/{id}"  and pushes it */
  protected closeItem() {
    return closeItem.call(this);
  }
}
