





















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class App extends Vue {
  pending = true;
  created() {
    const p1 = this.$store.dispatch("App/readSettingsOnce");
    Promise.all([p1]).then(() => {
      this.pending = false;
    });
  }
  mounted() {
    // eslint-disable-next-line
    console.info(
      "%cMade By",
      `
      display: block;
      width: 90%;
      padding: 80px 60px 10px;
      color: #D1FB84;
      background-color: black;
      background-image: url(https://prk-a.ru/assets/projecta/i/logo--main.svg);
      background-size: contain;
      background-position: 80%;
      background-size: 90%;
      background-repeat: no-repeat;
      font: 800 1.3em monospace;
      text-shadow: 0 0 10px #D1FB84;
      border-radius: 5px`
    );
  }
}
