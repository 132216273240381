<template>
  <div class="badge-list">
    <span v-for="item in normList" :key="item.value" class="badge-list_item">
      {{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    list: { type: Array, required: true },
  },

  computed: {
    normList() {
      if (!this.list) return [];
      return this.list.map(r => {
        if (r.name) return r;
        if (r.value) return { value: r.value, name: r.value };
        return { value: r, name: r };
      });
    },
  },
};
</script>

<style lang="scss">
.badge-list {
  &_item {
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 2px 10px;
    margin: 1px 5px;
  }
}
</style>
