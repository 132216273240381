



































































import rest from "@/utils/rest";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AppCluster extends Vue {
  clusterList: App.ClusterApp[] | null = null;
  mounted() {
    if (this.clusterList) return;
    this.getClusterList();
  }

  get nudgeLeft() {
    if (!this.clusterList) return 8;
    if (!this.clusterList.length) return 8;
    if (this.clusterList.length === 1) return 10;
    if (this.clusterList.length === 2) return 80;
    return 160;
  }
  get clusterListLength() {
    if (!this.clusterList) return 0;
    if (this.clusterList) return this.clusterList.length;
    return 0;
  }

  get pending() {
    return null;
  }
  getClusterList() {
    rest
      .get("v1/app/cluster_menu/")
      .then(res => {
        this.clusterList = res;
        this.$store.commit("App/SET", { clusterMenu: res });
      })
      .catch(err => {
        this.$mktoast.danger(err.message, err.name);
      });
  }
  getCardColor(color: null | string) {
    if (!color) return "#cdd9dd";
    return color;
  }
  getCardIcon(icon: null | string) {
    if (!icon) return "server";
    return icon;
  }
  /** отрабатываем клик на модуле */
  clickAction(cluster: App.ClusterApp) {
    if (cluster.sys_name == "sso") this.$go("/admin/users");
    // return window.open(cluster.uri);
    else return window.location.replace(cluster.uri);
  }
}
