<template>
  <tbody class="view-tbody" :class="{isClosed}">
    <tr v-if='groupName' class="tbody__head" @click="isClosed=!isClosed">
      <th :colspan="tdCount">
        <span class="tbody__open-indicator">{{isClosed?'+':'-'}}</span>
        <span class="tbody__name">{{groupName}}</span>
        <span class="tbody__count">[{{rows.length}}]</span>
      </th>
    </tr>

    <v-tr
      v-for="row in rows"
      :key="'row-'+row.rowIndex"

      :row = "row"
      :selectable="selectable"
      :deletable="deletable"
      :selected="selected"
      :active="active"

      @cellClick="$emit('cellClick', $event)"
      @recordClick="$emit('recordClick', $event)"
      @recordDelete="$emit('recordDelete', $event)"
      @recordSelect="$emit('recordSelect', $event)"
      @recordDeselect="$emit('recordDeselect', $event)"
    />
  </tbody>
</template>

<script>
import vTr   from './v-tr';


export default {
  name: 'v-tbody',

  components: {vTr},

  props: {
    // data and config
    groupName:    { type: String },
    rows:         { type: Array },
    schema:       { type: Array },
    // behaviour config
    selectable:   { type: Boolean, default: false },
    deletable:    { type: Boolean, default: false },
    multisort:    { type: Boolean, default: false },
    // display config
    trClass:      { type: [String, Function] },
    // user interaction
    selected:     { type: Array },
    active:       { type: Object }
  },

  data(){ return {
    isClosed: false
  }; },

  computed: {
    tdCount(){
      let count = this.schema.length;
      if(this.selectable) count++;
      if(this.deletable) count++;
      return count;
    },
  },

  methods: {

  },

};
</script>

<style lang="scss">
.view-tbody{
  &.isClosed{
    tr{display: none;}
    tr.tbody__head{ display: table-row; }
  }

  .is-null, .is-undefined{
    opacity: 0.5;
  }
}

.tbody{
  &__open-indicator{
    display: inline-block;
    width: 1em;
  }
  &__count{
    margin-left: 10px;
  }
}

</style>
