









































import { clone } from "@/utils/utils-obj";
import { Component, Prop, Vue } from "vue-property-decorator";

interface ItemsPerPageOptions {
  count: number;
  name: string;
}

interface Pagination {
  page: number;
  itemsPerPage: number;
}

@Component({
  components: {},
})
export default class SimplePagination extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        page: 1,
        itemsPerPage: 10,
      };
    },
  })
  public options!: Pagination;
  @Prop({
    type: [Array],
    default: () => {
      return [
        { count: 5, name: "5" },
        { count: 10, name: "10" },
        { count: 15, name: "15" },
        { count: -1, name: "Все" },
      ];
    },
  })
  public itemsPerPageOptions!: ItemsPerPageOptions[];
  @Prop({ type: Number, required: true }) public length!: number;
  private pagination: Pagination | null = null;

  private beforeMount() {
    this.pagination = clone(this.options);
    this.$emit("change-page", this.pagination);
  }

  protected itemsPerPageChange() {
    if (this.pagination) this.pagination.page = this.options.page;
    this.$emit("change-page", this.pagination);
  }

  private nextPage() {
    if (!this.pagination) return;
    this.pagination.page++;
    this.$emit("change-page", this.pagination);
  }
  private prevPage() {
    if (!this.pagination) return;
    this.pagination.page--;
    this.$emit("change-page", this.pagination);
  }
}
