<template>
  <div class="table-view mk-table">
    <div   v-if='!rowsList'             :class="nullClass">{{nullText}}</div>
    <div   v-else-if='!rowsList.length && !isSearchable' :class="zeroClass">{{zeroText}}</div>

    <table v-else :class="tableClass">
      <v-head
        :schema="normSchema"
        :deletable="deletable"
        :selectable="selectable"
        :multisort="multisort"
        @sort="onSort"
        @searchInput="onSearch"
        @filterInput="onFilter"

        :pageSize="pageSize"
        :page="page"
        :displayedRecordsCount="displayedRecordsCount"
        :allRecordsCount="allRecordsCount"
        @pageChange="onPageChange"
      />

      <template v-if="rowsList.length">
        <v-tbody
          v-for="(group) in rowGroups"
          :key="'gr-'+group._groupName"
          :groupName="group._groupName"
          :rows = "group._rows"
          :schema="normSchema"

          :selectable="selectable"
          :deletable="deletable"
          :selected="selected"
          :active="active"

          @cellClick="$emit('cellClick', $event)"
          @recordClick="emitRecordClick"
          @recordDelete="$emit('recordDelete', $event)"
          @recordSelect="selectionAdd"
          @recordDeselect="selectionRemove"
        >
        </v-tbody>
      </template>

      <tr v-else>
        <td :colspan="tdCount">{{zeroText}}</td>
      </tr>

     </table>

  </div>
</template>

<script>
/**
 *
 * TODO: в searchable в инпутах сделать сброс и возможно кнопку "поиск"
 * TODO: в хедере столбца selectable сделать попап для "выбрать все", "снять выбор", "инвертировать выбор"
 * TODO: чтобы кнопка delete могла задавать вопрос "точно удалить?"
 */
import vHead  from './components/v-head';
import vTbody from './components/v-tbody'
import normalize  from './components/normalize';


export default {
  name: 'table-view-2',

  components: {vHead, vTbody},

  props: {
    // data and config
    records:      { type: Array },
    rows:         { type: Array }, // rendered records, has priority to records
    schema:       { type: Array },
    // behaviour
    selectable:   { type: Boolean, default: false },
    activatable:  { type: Boolean, default: false },
    deletable:    { type: Boolean, default: false },
    multisort:    { type: Boolean, default: false },
    // display config
    tableClass:   { type: String, required: false, default: 'table table-striped table-bordered table-hover' },
    nullText:     { type: String, default: 'Ожидаем данные...' },
    zeroText:     { type: String, default: 'Нет записей' },
    nullClass:    { type: String, default: 'alert alert-info' },
    zeroClass:    { type: String, default: 'alert alert-warnig' },
    trClass:      { type: [String, Function] },
    groupBy:      { type: String },
    // pagination
    pageSize:     { type: Number, default: 10 },
    page:         { type: Number, default: 0 },
    allRecordsCount: { type: Number, default: 50 },
    displayedRecordsCount: { type: Number, default: 13 },
  },

  data(){ return {
    selected: [],
    active: null,
    search: null,
    sort: {},
    filter: {},
  }; },

  computed: {
    normSchema(){
      if(this.schema) return normalize.recordSchema(this.schema);
      return normalize.createSchema(this.records[0]);
    },
    rowsList(){
      if(this.rows) return this.rows;
      normalize.schema = this.normSchema;
      return normalize.rowList(this.records);
    },
    rowGroups(){
      return normalize.group(this.rowsList, this.groupBy, this.normSchema);
    },
    tdCount(){
      let count = this.schema.length;
      if(this.selectable) count++;
      if(this.deletable) count++
      return count;
    },
    isSearchable(){
      if(!this.normSchema) return false;
      // at least one record has "searchable" flag set to true
      return Boolean( this.normSchema.find(r => r.searchable) );
    },
  },

  methods: {
    emitRecordClick(record){
      this.$emit('recordClick', record);
      if(this.activatable) this.activate(record);
    },
    activate(record){
      this.active = (this.active === record) ? null : record;
      this.$emit('recordActivate', this.active);
    },
    selectionAdd(record){
      this.selected.push(record);
      this.$emit('select', this.selected);
      this.$emit('selectionAdd', record);
    },
    selectionRemove(record){
      this.selected = this.selected.filter(r => r !== record);
      this.$emit('select', this.selected);
      this.$emit('selectionRemove', record);
    },
    onSort(sort){
      this.sort = sort;
      this.$emit('sort', sort);
    },
    onSearch(search){
      this.search = search;
      this.$emit('searchInput', search);
      if(this.pageSize) this.$emit('pageChange', 0);
    },
    onFilter(filter){
      this.filter = filter;
      this.$emit('filterInput', filter);
      if(this.pageSize) this.$emit('pageChange', 0);
    },
    onPageChange(page){ this.$emit('pageChange', page); }
  },

};
</script>

<style lang="scss">
  .table-view{
    border: none;

    tr{
      transition: background-color 0.1s;
      cursor: pointer;
    }
  }

  .td-key_select,
  .th-key_select,
  .td-key_delete,
  .th-key_delete{
    width: 34px;
  }
</style>
