/** Wrapper for RESTfull web service with JSON data format */

import * as str from "./utils-strings";

import cfg from "@/config";
import http from "./http";

export class RestError extends Error {
  val?: any;
  httpStatus?: number;

  constructor(exception: any, response: Response, messageOverride = "") {
    super(messageOverride || exception.message || "Error occured");
    this.name = exception.error || exception.type || exception.name || "REST ERROR";
    if (exception.val) this.val = exception.val;
    this.httpStatus = response.status;
  }
}
interface RestOptions {
  [index: string]: any;
  apiUrl: string;
  onReceive: ((response: Response) => void) | null;
  beforeSend: ((method: string, params: any, options: any) => void) | null;
  http: {
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    headers: Record<string, string>;
  };
}

/** @namespace rest REST remote procedure call */
const rest = {
  Authorization: "",

  defaults: {
    apiUrl: cfg.apiUrl,
    // (response: Response)=>void <arg> is (response), <this> is a rest module
    onReceive: null,
    // (method: string, params: any, options: any)=>void
    beforeSend: null, // <args> is (method, params, options), <this> is a rest module
    http: {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    },
  },

  // deep merge with target priority
  normalizeOptions(target: Partial<RestOptions>, defaults?: RestOptions): RestOptions {
    const def = (defaults || this.defaults) as RestOptions;
    Object.keys(def).forEach(key => {
      if (target[key] === undefined) {
        target[key] = def[key];
      } else if (typeof target[key] === "object") {
        target[key] = this.normalizeOptions(target[key], def[key]);
      }
    });
    return target as RestOptions;
  },

  serializeBody(data: any) {
    if (data instanceof FormData) return data;
    return JSON.stringify(data);
  },

  /**
   * asynchroniously extracts result and check for errors
   * @param {Response} response
   * @returns {Promise}
   */
  exractResult(response: Response): Promise<any> {
    return response
      .text() // DON"t use json() to check json format manually
      .then((responseText: string) => {
        let result: any;
        // try to parse JSON if string is not empty
        try {
          result = responseText ? JSON.parse(responseText) : "";
          // result = JSON.parse(responseText);
          // missformatted JSON
        } catch (e) {
          const msg1 = `STATUS: ${response.status}. RESPONSE: ` + str.cut(str.stripTags(responseText), 100);
          throw new RestError(result, response, "INVALID RESPONSE");
          // const err: any = new Error(msg);
          // err.name = "INVALID RESPONSE";
          // throw err; // ERROR
        }

        // HTTP status not OK
        if (response.status < 200 || response.status > 300) {
          if (result.error && result.message && result.path) {
            if (result.message === "No message available") {
              result.message = `"${result.path}" Status: ${result.status}, ${result.error}`;
            }
            throw new RestError(result, response);
            // throw {
            //   name: result.error.toUpperCase(),
            //   message: result.message
            // }
          }
          const type = (result.error && result.error.type) || result.type || "MISFORMATTED ERROR";
          const msg2 = (result.error && result.error.message) || result.message || JSON.stringify(result);
          throw new RestError(result, response);

          // const err = new Error(msg);
          // err.name = type;
          // (err as any).httpStatus = response.status;
          // (err as any).httpResponse = response;
          // throw err;
        }

        // if no error field specified, just return result
        if (!result.error) return result; // OK RESULT

        // the server responded with OK status, but error in body
        const msg = result.error.message || JSON.stringify(result.error);
        // const type = result.error.type || "RPC ERROR";
        throw new RestError({ message: msg, name: "ERROR" }, response);
        // const err = new Error(msg);
        // err.name = type;
        // throw err; // ERROR
      });
  },

  call(method: string, params?: any, options: Partial<RestOptions> = {}) {
    const localOpts = this.normalizeOptions(options) as RestOptions;
    if (this.Authorization) {
      localOpts.http.headers.Authorization = this.Authorization;
    }
    const url = isAbsUrl(method) ? method : str.normalizePath(localOpts.apiUrl + method);

    let data: any;
    if (localOpts.http.method === "GET") {
      data = params; // leave url-searialization for http module
    } else if (data instanceof FormData) {
      data = params; // leave for automaic formatting by Fetch API
    } else {
      data = this.serializeBody(params);
    }
    if (typeof localOpts.beforeSend === "function") {
      localOpts.beforeSend.call(this, method, params, localOpts);
    }

    return http
      .send(url, data, localOpts.http)
      .then((response: any) => {
        // add information about REST
        response.rest = { method, params, localOpts, url, data };
        if (typeof localOpts.onReceive === "function") {
          localOpts.onReceive.call(this, response);
        }
        return response;
      })
      .then(this.exractResult);
  },

  injectHttpMethod(options: any = {}, methodName: string) {
    if (!options.http) options.http = {};
    options.http.method = methodName;
    return options;
  },
  get(method: string, params?: any, options?: any) {
    return this.call(method, params, this.injectHttpMethod(options, "GET"));
  },
  put(method: string, params?: any, options?: any) {
    return this.call(method, params, this.injectHttpMethod(options, "PUT"));
  },
  post(method: string, params?: any, options?: any) {
    return this.call(method, params, this.injectHttpMethod(options, "POST"));
  },
  patch(method: string, params?: any, options?: any) {
    return this.call(method, params, this.injectHttpMethod(options, "PATCH"));
  },
  delete(method: string, params?: any, options?: any) {
    return this.call(method, params, this.injectHttpMethod(options, "DELETE"));
  },
};

export default rest;

// helpers
function isAbsUrl(str: string) {
  return /^\w+:\/\//.test(str);
}
