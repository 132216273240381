






















import { Component, Vue } from "vue-property-decorator";
import ModuleInfoCard from "./elements/module-info-card.vue";
// import ModulesConfig from "@/modules/_cfg";
// import extModules from "./elements/ext-modules";

@Component({
  components: { ModuleInfoCard },
})
export default class Home extends Vue {
  beforeMount() {
    // this.$store.dispatch("Stops/readList").then(() => this.$store.dispatch("Stops/watchZabbix"));
    // this.$store.dispatch("Vehicles/readList").then(() => this.$store.dispatch("Vehicles/watchSnapshot"));
    // this.$store.dispatch("Videocams/loadCamerasList").then(() => this.$store.dispatch("Videocams/loadBindPlacements"));
    // this.$store.dispatch("Videoarchive/readList");
    /**
     * запрос реального состояния пилонов остановок.
     */
    // this.$store.dispatch("Stops/watchZabbix");
  }
  mounted() {
    if (this.$route.params && this.oldUrl != "/login")
      if (this.oldUrl) this.$go(this.oldUrl);
  }
  get clusterMenu() {
    return this.$store.state.App.clusterMenu;
  }
  get oldUrl() {
    if (!this.$store.state.App.url) return null;
    return this.$store.state.App.url.match(/(?<=#).*/g).join("");
  }
  /*modulesConfig & menuItems использовались для мнею на главной стр, но сейчас эта инфо берется
  из store.state.App.cllusterMenu*/
  // get modulesConfig() {
  //   return ModulesConfig;
  // }
  // get menuItems(): App.ModuleDetails[] {
  //   return ModulesConfig.concat(extModules)
  //     .filter(m => !m.roles || this.$adminOr(...m.roles))
  //     .filter(m => !m.hideFromMenu)
  //     .sort(this.menuSort);
  // }

  // private menuSort(a: App.ModuleDetails, b: App.ModuleDetails): number {
  //   if (a.menuWeight === undefined) a.menuWeight = 10000;
  //   if (b.menuWeight === undefined) b.menuWeight = 10000;
  //   return a.menuWeight - b.menuWeight;
  // }
}
