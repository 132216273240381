import vue from "vue";
import * as str from "@/utils/utils-strings";
import rest from "@/utils/rest";
import toast from "mk-toast";

// COMMON HELPERS
export function clone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

// MUTATIONS ===

// INTERFACES
export interface ListPayload {
  listName: string;
  list: any[];
}
export interface KeyVal {
  [index: string]: any;
}
// helpers
function _getList(state: any, name: string): any[] {
  const list = state[name];
  if (!list) throw new Error(`Список с именем "${name}" не существует`);
  return list;
}
function _findItemIndex(state: any, listName: string, id: string | number) {
  const list: any[] = _getList(state, listName);
  const idx = list.findIndex(r => r.id === id);
  if (idx === -1) throw new Error(`Запись с идентификатором "${id}" не найдена в списке "${listName}"`);
  return idx;
}
// mutation functions
export function SET(state: any, payload: KeyVal) {
  Object.keys(payload).forEach((fieldName: string) => {
    state[fieldName] = payload[fieldName];
  });
}
/** update record in array */
export function ITEM_UPDATE(state: any, payload: { listName: string; data: any }) {
  const list = _getList(state, payload.listName);
  const id = payload.data.id;
  if (id === null || id === undefined) throw new Error("Id does not given for record update");
  const idx = _findItemIndex(state, payload.listName, id);

  const data = Object.assign({}, list[idx], payload.data);
  vue.set(list, idx, data);
}
/** add record to array */
export function ITEM_ADD(state: any, payload: ListPayload) {
  const list = _getList(state, payload.listName);
  list.unshift(payload.list);
}
/** removes records from array by id list */
export function ITEMS_DELETE(state: any, payload: { listName: string; ids: string[] | number[] }) {
  const list = _getList(state, payload.listName);
  const newList = list.filter((r: any) => !(payload.ids.includes as any)(r.id));
  state[payload.listName] = newList;
}

// ACTIONS ===

interface ReadPayload {
  url: string;
  fieldname: string; // name of field in vuex state
  params?: any;
  map?: (response: any) => any;
}

/** reads arbitrary data from server and store it in the vuex storage */
export function readServer(context: Storage, payloadOrUrl: ReadPayload | string) {
  const payload: ReadPayload = typeof payloadOrUrl === "string" ? { url: payloadOrUrl, fieldname: "" } : payloadOrUrl;
  if (!payload.fieldname) payload.fieldname = str.toCamelCase(payload.url);
  return rest
    .get(payload.url, payload.params)
    .then(response => (payload.map ? payload.map.call(null, response) : response))
    .then(response => {
      context.commit("SET", { [payload.fieldname]: response });
      return response;
    })
    .catch(error => {
      context.commit("SET", { [payload.fieldname]: { error } });
      toast.danger(error.message, error.name);
    });
}

// /** update stops*/
// export function stopsListUpdate(context: Storage){
//   const
// }
