import Vue from "vue";
// import router from "@/router";

// Vue.prototype.$go = (path: string) => {
//   if (router.currentRoute.path === path) return;
//   router.push(path);
// };
// declare module "vue/types/vue" {
//   // 3. Declare augmentation for Vue
//   interface Vue {
//     $go: (path: string) => void;
//   }
// }

declare global {
  interface Window {
    userInfo?: any;
  }
}

import moment from "moment";
moment.locale("ru");

import vuemktoast from "mk-toast/vue";
// import "mk-toast/dist/mk-toast.css";
Vue.use(vuemktoast);

import mktoast from "mk-toast";
window.onerror = msg => mktoast.danger(String(msg));


// complex widgets placed in separate folders
import TableView from "./commons/widgets/table-view-2/index.vue";
Vue.component("TableView", TableView);
import mkGrid from "./commons/widgets/table-view-2/mk-grid.vue";
Vue.component("mkGrid", mkGrid);

/** DEPRECATED ======= */

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// // Install BootstrapVue
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
