



























import { Component, Prop, Vue } from "vue-property-decorator";
import { clone } from "@/utils/utils-obj";

@Component({
  components: {},
})
export default class AdminToolsToolbar extends Vue {
  @Prop({ type: [Number] }) public length!: number;
  @Prop({ type: [Number] }) public totalRowsCount!: number;
  private localFilter: any = {};

  private created() {
    this.clearlocalFilter();
  }

  private clearlocalFilter() {
    const localFilter = {};
    this.localFilter = localFilter;
  }

  private emitPage(val: any) {
    this.$emit("change-page", val);
  }

  private emitFilter() {
    this.$emit("filter", clone(this.localFilter));
  }

  get itemsPerPageOptions() {
    if (!this.totalRowsCount) return undefined;
    return [
      { count: 5, name: "5" },
      { count: 10, name: "10" },
      { count: 15, name: "15" },
      { count: this.totalRowsCount, name: "Все" },
    ];
  }
}
