import Vue from "vue";
import config from "@/config";

Vue.prototype.$userInfo = window.userInfo;
Vue.prototype.$hasRole = function (rolename: App.Role, user: App.User): boolean {
  if (!user) user = this.$store.state.App.user;
  if (!user.roles) return false;
  return user.roles.includes(rolename);
};

Vue.prototype.$hasOneOfRoles = function (roles: App.Role[], user: App.User): boolean {
  if (!user) user = this.$store.state.App.user;
  if (!user || !user.roles) return false;
  if (!Array.isArray(roles)) throw new Error(`"roles" must be an array, ${typeof roles} given`);
  for (const rolename of roles) {
    if (user.roles.includes(rolename)) return true;
  }
  return false;
};

Vue.prototype.$goHomeIfNoRole = function (roles: App.Role[]) {
  if (!this.$store.state.App.authorized) return;
  if (this.$hasOneOfRoles(roles)) return;
  this.$router.push(config.mainPath);
};

// Declare augmentation for Vue
declare module "vue/types/vue" {
  interface Vue {
    $hasRole: (rolename: App.Role, user?: App.User) => boolean;
    $hasOneOfRoles: (roles: App.Role[], user?: App.User) => boolean;
    $goHomeIfNoRole: (roles: App.Role[]) => void;
    $userInfo: any;
  }
}
