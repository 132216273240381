<template>
  <div class="name-val" :class="cssClass">
    <div class="name-val__body">
      <span class="name-val__name" :title="name" v-html="name"></span>
      <span class="name-val__value" :title="value" v-html="localValue"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NameVal",

  props: {
    name: { type: String, default: "" },
    value: { default: "" },
    val: { default: "" },
    cols: { type: [String, Number], default: "" },
    line: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    fill: { type: Boolean, default: false },
  },

  computed: {
    cssClass() {
      const classList = [];
      if (this.cols) classList.push("cols" + this.cols);
      if (this.line) classList.push("line");
      if (this.border) classList.push("name-val_border");
      if (this.fill) classList.push("name-val_fill");
      return classList.join(" ");
    },
    localValue() {
      if (this.value !== "") return this.value;
      return this.val;
    },
  },
};
</script>

<style lang="scss">
.name-val {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 5px;
  vertical-align: top;

  &.cols2 {
    width: 50%;
  }
  &.cols3 {
    width: 33%;
  }
  &.cols4 {
    width: 25%;
  }

  &__body {
    padding: 10px;
    border-radius: 3px;
    text-align: center;
  }

  &__name {
    display: block;
    color: rgba($color: #000000, $alpha: 0.5);
    font-size: 0.7em;
  }
  &__value {
    display: block;
    font-size: 1.1em;
    overflow: hidden;
  }
}
.name-val.line {
  padding: 5px 0;

  .name-val__body {
    padding: 0px;
    border: none;
    text-align: left;
  }
  .name-val__name {
    display: inline-block;
    // color:rgba($color: #000000, $alpha: 0.5);
    font-size: 0.9em;
    width: 30%;
    vertical-align: top;
    padding: 2px 5px 2px 0;
  }
  .name-val__value {
    display: inline-block;
    font-size: 1em;
    width: 70%;
    vertical-align: top;
    padding: 2px 0 2px 5px;
  }
}
.name-val_border {
  .name-val__body {
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
}
.name-val_fill {
  .name-val__body {
    background-color: rgba($color: #000000, $alpha: 0.03);
  }
}
</style>
