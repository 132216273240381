









import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { clone } from "@/utils/utils-obj";
import rest from "@/utils/rest";
import InfoForm from "./_record-form.vue";

@Component({
  components: { InfoForm },
})
export default class AdminForm extends Vue {
  @Prop({ type: [Number, String] }) public activeId!: string | number;
  private localItem: App.User | null = null;
  private error: any = null;
  private isBusy = false;
  protected heightForm = 400;

  private created() {
    this.readRole();
  }
  @Watch("activeId")
  private onIdChange() {
    this.readRole();
  }

  get mode() {
    if (this.activeId === "_new") return "create";
    return "edit";
  }

  private readRole() {
    this.error = "";
    this.localItem = null;
    if (this.activeId === null) return;
    if (this.mode === "create") {
      return this.assignData(null);
    }
    if (this.mode !== "edit") {
      throw new Error(`Unknown mode "${this.mode}"`);
    }

    // edit
    this.isBusy = true;
    rest
      .get(`v1/role/${this.activeId}`)
      .then(res => {
        this.assignData(res[0]);
      })
      .catch(e => {
        this.error = e.message;
      })
      .finally(() => {
        this.isBusy = false;
      });
  }
  private assignData(user: any) {
    this.localItem = clone(user);
  }
}
