<template>
  <div class="table-pagination">
    <template v-if="pagesCount>1">

      <a
        v-if="prev"
        class="table-pagination_prev table-pagination_btn"
        :class="{disabled: localPage < 1}"
        @click="setPage(localPage - 1)"
      >&lt;</a>

      <a
        v-if="minPage > 0"
        class="table-pagination_more table-pagination_btn"
        @click="setPage(localPage - 1)"
      >...</a>

      <a
        v-for="page in pagesList"
        :key = "'page'+page"
        @click = "setPage(page)"
        :class="{active: page === localPage}"
        class="table-pagination_btn"
      >
        {{page + 1}}
      </a>

      <a
        v-if="maxPage < pagesCount - 1 "
        class="table-pagination_more table-pagination_btn"
        @click="setPage(localPage + 1)"
      >...</a>

      <a
        v-if="next"
        class="table-pagination_next table-pagination_btn"
        :class="{disabled: localPage >= pagesCount - 1}"
        @click="setPage(localPage + 1)"
      >&gt;</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'pagination',

  props: {
    value:        { type: Number, default: 0 }, // page number
    pageSize:     { type: Number, default: 0 },
    displayedRecordsCount: { type: Number, default: 0 },
    allRecordsCount: { type: Number, default: 0 },
    next:         {type: Boolean, default: true},
    prev:         {type: Boolean, default: true},
    offset:       {type: Number, default: 2}, // buttons count at left/right side of current page
  },

  beforeMount(){ this.localPage = this.value; },

  watch: {
    value(){ this.localPage = this.value; },
  },

  data(){ return {
    localPage: 0
  }; },

  computed: {
    pagesCount(){
      if(this.pages) return this.pages;
      if(!this.pageSize) return 0;
      return Math.ceil( this.allRecordsCount/this.pageSize );
    },
    pagesList(){
      let from = this.localPage - this.offset;
      if(from < 0) from = 0;
      let to = this.localPage + this.offset;
      if(to >= this.pagesCount) to = this.pagesCount - 1;

      const res = [];
      for(let i = from; i <= to; i++) res.push(i);
      return res;
    },
    minPage(){ return this.pagesList[0]; },
    maxPage(){ return this.pagesList[ this.pagesList.length - 1 ]; },

  },

  methods: {
    setPage(num){
      this.localPage = num;
      this.$emit('input', num);
    }
  }

}
</script>

<style lang="scss">
.table-pagination{
  display: inline-block;
}
    a.table-pagination_btn{
      display: inline-block;
      border: 1px solid rgba(0,0,0,0.1);
      background-color: #fff;
      color: #555;
      border-right-width: 0px;
      cursor: pointer;
      user-select: none;
      padding: 0 5px;

      &:first-child{
        border-radius: 3px 0 0 3px;
      }
      &:last-child{
        border-right-width: 1px;
        border-radius: 0 3px 3px 0;
      }

      &.active{
        background-color: #00539C;
        color: #fff;
      }
      &.disabled{
        background-color: #eee;
        color: #999;
        pointer-events: none;
        cursor: default;
      }
    }
</style>
