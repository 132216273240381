<template>
  <div class="x-block" :class="localValue ? 'x-block_open' : 'x-block_closed'">
    <header class="x-block_header" @click="toggle">
      <svg class="x-block_caret" width="16" height="16" viewBox="0 0 16 16">
        <polygon points="10.5,8 5.5,12.5 5.5,3.5 " />
      </svg>
      <span class="x-block_name"
        ><slot name="name">{{ name }}</slot></span
      >
    </header>

    <!-- intended for animation and scrolling -->
    <div class="x-block_body-wrapper" :class="bodyWrapperIsScrollable ? 'x-block_body-scroll' : ''" :style="wrapperStyle">
      <!-- content -->
      <div class="x-block_body" :class="{ 'x-block_scroll': scroll }" :style="bodyStyle">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-block",

  props: {
    value: { type: Boolean, default: true },
    name: { type: String },
    scroll: { type: Boolean, default: true },
    height: { type: Number },
    maxHeight: { type: Number },
    minHeight: { type: Number },
  },

  beforeMount() {
    this.localValue = this.value;
  },
  mounted() {
    this.$nextTick(this.storeWrapperHeight);
  },

  watch: {
    value() {
      this.localValue = this.value;
    },
    wrapperStyle() {
      // it changes between "open" and "close"
      this.storeWrapperHeight();
      this.pauseScrollability();
    },
  },

  data() {
    return {
      localValue: true, // true - is open
      wrapperHeight: null, // max-height for animation

      bodyWrapperIsScrollable: true, // must be swithed off during transition
    };
  },

  computed: {
    bodyWrapper() {
      if (!this.$el) return;
      return this.$el.querySelector(".x-block_body-wrapper");
    },
    // changes open and close condition
    wrapperStyle() {
      // is closed
      if (!this.localValue) return "max-height: 0;";
      // is open
      if (this.wrapperHeight) return `max-height: ${this.wrapperHeight}px;`;
      return "";
    },
    bodyStyle() {
      let style = "";
      if (this.height) style += ` height: ${this.height}px`;
      if (this.minHeight) style += ` min-height: ${this.minHeight}px`;
      return style;
    },
  },

  methods: {
    toggle() {
      this.localValue = !this.localValue;
      this.$emit("input", this.localValue);
    },
    /** stops body scroll (hides rullers) for 0.3s - while transition is going */
    pauseScrollability() {
      this.bodyWrapperIsScrollable = false;
      setTimeout(() => {
        this.bodyWrapperIsScrollable = true;
      }, 300);
    },
    storeWrapperHeight() {
      // if height is imposed as a prop - use it,
      if (this.height || this.maxHeight) {
        this.wrapperHeight = this.height || this.maxHeight;

        // otherwise take height of all body content
      } else if (this.bodyWrapper) {
        const H = this.bodyWrapper.scrollHeight;
        // probably element is hidden, no reason to remember this value
        if (H) this.wrapperHeight = H;
      }
    },
  },
};
</script>

<style lang="scss">
.x-block {
  &_header {
    border-radius: 2px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  &_caret {
    vertical-align: middle;
    transition: transform 0.3s;
  }

  &_body-wrapper {
    transition: opacity 0.3s, max-height 0.3s;
  }

  &_body.x-block_scroll {
    overflow: auto;
  }
}

// OPEN/CLOSE LOGIC ===
.x-block_open {
  .x-block_caret {
    transform: rotate(90deg);
  }
}
.x-block_closed {
  .x-block_caret {
    transform: rotate(0deg);
  }

  .x-block_body-wrapper {
    opacity: 0;
    overflow: hidden;
  }
}

// BOOTSTRAP PATCH
.x-block {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>
