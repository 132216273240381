











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ModuleInfoCard extends Vue {
  @Prop({ type: [Object] }) public module!: App.ClusterApp;

  get getCardStyle() {
    return { "animation-delay": 0.1 * (this as any).$attrs.i + "s" };
  }
  get name() {
    if (!this.module) return "";
    if (!this.module.name) return this.module.sys_name;
    return this.module.name;
  }
  get sys_name() {
    if (!this.module) return "none";
    return this.module.sys_name;
  }
  get info() {
    if (!this.module) return "";
    if (!this.module.info) return "Программный модуль";
    return this.module.info;
  }
  get icon() {
    if (!this.module) return "";
    return this.module.icon;
  }
  get bg() {
    if (this.module.bg_color) return this.module.bg_color;
    return "";
  }

  /** отрабатываем клик на модуле */
  clickAction() {
    if (this.sys_name == "sso") this.$go("/admin/users");
    else return window.location.replace(this.module.uri);
  }
}
