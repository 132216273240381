














/** Menu of app modules */

import { Component, Vue } from "vue-property-decorator";
import ModulesConfig from "@/modules/_cfg";

@Component
export default class AppTopMenu extends Vue {
  get menuItems(): App.MenuItem[] {
    return ModulesConfig.filter(m => !m.roles || this.$hasOneOfRoles(m.roles))
      .filter(m => !m.hideFromMenu)
      .sort(this.menuSort)
      .map(m => ({
        path: "/" + m.id,
        url: "/" + m.id,
        name: m.name,
        icon: m.icon,
      }));
  }
  get moduleRoot() {
    return this.getRootDir(this.$route.path);
  }

  get vehiclesErrors() {
    if (!this.$store.state.Vehicles.sensorsMap) return null;
    let i = 0;
    Object.values(this.$store.state.Vehicles.sensorsMap).forEach((vehicle: any) => {
      if (vehicle.failures && Object.keys(vehicle.failures).length) i++;
    });
    return i;
  }

  get stationsErrors() {
    if (!this.$store.state.ChargingStations.chargersState) return null;
    let i = 0;
    Object.values(this.$store.state.ChargingStations.chargersState).forEach((stations: any) => {
      if (stations.error && Object.keys(stations.error).length) i++;
    });
    return i;
  }

  get maintenanceCount() {
    if (!this.$store.state.Maintenance.count) return null;
    return this.$store.state.Maintenance.count;
  }

  private colorBadge(item: App.MenuItem) {
    if (item.path === "/maintenance") return "#fb8c00";
    return "red";
  }

  private isShow(item: App.MenuItem) {
    if (item.path === "/vehicles") return this.vehiclesErrors;
    if (item.path === "/charging-stations") return this.stationsErrors;
    if (item.path === "/maintenance") return this.maintenanceCount;
    return false;
  }

  private getRootDir(path: string) {
    // const trimmed = path.replace(/^\//, "");
    // const matches = trimmed.match(/^(\w)+-*(\w)+/);
    // return matches ? "/" + matches[0] : "/";
    const matches = path.split("/")[1];
    return matches ? "/" + matches : "/";
  }

  private menuSort(a: App.MenuItem, b: App.MenuItem): number {
    if (a.menuWeight === undefined) a.menuWeight = 10000;
    if (b.menuWeight === undefined) b.menuWeight = 10000;
    return a.menuWeight - b.menuWeight;
  }

  private go(path: string) {
    if (this.$route.path === path) return;
    this.$router.push(path);
  }
}
