



















import { Component, Prop, Vue } from "vue-property-decorator";
import topMenu from "./elements/app-top-menu.vue";
import appAsideMenu from "./elements/app-aside-menu.vue";
import appUser from "./elements/app-user.vue";

@Component({
  components: {
    topMenu,
    appAsideMenu,
    appUser,
  },
})
export default class AppLayout extends Vue {
  @Prop({ type: Boolean }) public noScroll!: boolean;

  public drawer = null;
  /** параметры, прочитанные из таблицы БД app_settings */
  get appSettings() {
    return this.$store.state.App.settings as { [key: string]: string } | null;
  }
  /** цвет фона логотипа из таблицы БД app_settings */
  get logoBg() {
    if (!this.appSettings) return null;
    return this.appSettings.logo_bg || null;
  }
  /** SVG код логотипа из таблицы БД app_settings */
  get logoSvg() {
    const defaultCode = `<div style="opacity: 0.6; margin-top:20px;">
    <i aria-hidden="true" class="v-icon notranslate mdi mdi-radio-tower"></i></div>`;

    if (!this.appSettings) return defaultCode;
    return this.appSettings.logo_svg || defaultCode;
  }
  gohome() {
    this.$go("/.");
  }
}
