import Vue from "vue";

Vue.prototype.$go = function (path: string) {
  if (this.$route.path === path) return;
  this.$router.push(path);
};

Vue.prototype.$isSet = (val: any) => {
  if (val === undefined) return false;
  if (val === null) return false;
  return true;
};

// VUE type augmentation
declare module "vue/types/vue" {
  interface Vue {
    /** cокращенная форма к this.$router.push(path) */
    $go: (path: string) => void;
    /** возвращает true если переменная не равна null и undefined */
    $isSet: (val: any) => boolean;
  }
}
