











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { clone } from "@/utils/utils-obj";
import rest from "@/utils/rest";

@Component({
  components: {},
})
export default class AdminInfoForm extends Vue {
  @Prop({ type: [Number, String] }) public activeId!: string | number;
  @Prop({ type: [Object] }) public item!: any;
  private localItem: App.Role | null = null;
  private isPending = false;
  private error: any = null;
  private rules = {
    required: (v: any) => !!v || "Поле обязательно для заполнения",
  };

  private beforeMount() {
    this.assignData();
  }

  @Watch("activeId")
  private onIdChange() {
    this.assignData();
  }
  @Watch("item")
  private onItemChange() {
    this.assignData();
  }

  get mode() {
    if (this.activeId === "_new") return "create";
    return "edit";
  }
  get createIsAvailable() {
    if (!this.localItem) return false;
    const it = this.localItem;
    if (!this.isSet(it.sysName) || !this.isSet(it.name)) return false;
    return true;
  }
  get editIsAvailable() {
    if (!this.localItem) return false;
    const it = this.localItem;
    if (!this.isSet(it.id) || !this.isSet(it.sysName) || !this.isSet(it.name)) return false;
    return true;
  }

  private isSet(val: any) {
    return val !== null && val !== undefined && val !== "";
  }
  private getBlankItem() {
    return {
      id: null,
      sysName: null,
      name: null,
    };
  }

  private assignData() {
    this.error = "";
    this.isPending = false;
    this.localItem = this.item ? clone(this.item) : this.getBlankItem();
  }

  private createClient() {
    this.isPending = true;
    this.localItem ? (this.localItem = parseInputObj(this.localItem)) : (this.localItem = null);
    this.error = "";
    if (!this.localItem) return (this.isPending = false);
    delete (this.localItem as any).id;

    rest
      .post(`v1/role`, this.localItem)
      .then(() => {
        this.$mktoast.success("Роль добавлена");
        this.$emit("item-create", this.localItem);
        this.assignData();
        this.$go("/admin/roles");
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isPending = false;
      });
  }
  private updateClient() {
    this.isPending = true;
    this.localItem ? (this.localItem = parseInputObj(this.localItem)) : (this.localItem = null);
    this.error = "";
    if (!this.localItem) return (this.isPending = false);

    rest
      .put(`v1/role`, this.localItem)
      .then(() => {
        this.$mktoast.success("Роль изменена");
        this.$emit("item-update", this.localItem);
        this.assignData();
        this.$go("/admin/roles");
      })
      .catch(e => {
        this.$mktoast.danger(e.message, e.name);
        this.error = e.message;
      })
      .finally(() => {
        this.isPending = false;
      });
  }
  private deleteClient() {
    this.isPending = true;
    this.error = "";

    if (confirm("Вы точно хотите удалить роль?")) {
      rest
        .delete(`v1/role/${this.localItem?.id}`)
        .then(() => {
          this.$mktoast.success("Роль успешно удалена");
          this.$emit("item-update");
          this.$go("/admin/roles");
        })
        .catch(e => {
          this.$mktoast.danger(e.message, e.name);
          this.error = e.message;
        })
        .finally(() => {
          this.isPending = false;
        });
    }
  }
}

function parseInput(val: any): string {
  if (!val) return val;
  if (typeof val !== "string") return val;
  return val.replace(/<.*?>/gim, " ").trim();
}

function parseInputObj(obj: any) {
  const res: any = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === "") res[key] = null;
    else res[key] = parseInput(obj[key]);
  });
  return res;
}
