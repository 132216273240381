<template>
  <tr
    v-if="row"
    :class="cssClass"
  >
      <td
        v-if="selectable"
        @click.stop="emitSelect"
        class="td-key_select"
      ><s-checkbox /></td>

      <v-td
        v-for="(cell,i) in row.cells"
        :key="i+'-'+cell.schema.key"
        :cell="cell"
        @cellClick="emitRecordClick"
      />

      <td
        v-if="deletable"
        @click.stop="emitDelete"
        class="td-key_delete"
      >
        <fa-icon icon="times"/>
      </td>

  </tr>
</template>

<script>
import vTd from './v-td';
import sCheckbox from '../icons/checkbox';

export default {

  components: { vTd, sCheckbox },

  props: {
    row:          { type: Object },

    selectable:   { type: Boolean, default: false },
    deletable:    { type: Boolean, default: false },
    selected:     { type: Array, required: true },
    active:       { type: Object },
  },

  computed: {
    isSelected(){
      if(!this.selected) return false;
      if(!this.selected.length) return false;
      return this.selected.includes(this.row.record);
    },
    isActive(){
      if(!this.active) return false;
      return this.row.record === this.active;
    },
    cssClass(){
      let css = this.row.cssClass;
      if(this.isSelected) css += ' selected';
      if(this.isActive) css += ' active';
      return css.trim();
    }
  },

  methods: {
    emitRecordClick(cellData){
      this.$emit('cellClick', cellData);
      this.$emit('recordClick', this.row.record);
    },
    emitSelect(){
      const eventName = this.isSelected ? 'recordDeselect' : 'recordSelect';
      this.$emit(eventName, this.row.record);
    },
    emitDelete(){
      this.$emit('recordDelete', this.row.record);
    },
  }

};
</script>

<style lang="scss">
  .mk-table{
    tr.selected{
      $even-color: #ecf1f8;
      $odd-color: darken($even-color, 5%);

      &:nth-child(odd){ background-color: $odd-color; }
      &:nth-child(even){ background-color: $even-color; }
    }

    tr.active{
      $even-color: #ffebb3;
      $odd-color: darken($even-color, 5%);

      &:nth-child(odd){ background-color: $odd-color; }
      &:nth-child(even){ background-color: $even-color; }
    }
  }
</style>
