<template>
  <thead>
    <!-- pagination -->
    <tr v-if="hasControlRow" class="pagination-row">
      <th v-if="pageSize" class="th-key_pagination" :colspan="colsCount">
        <pages-stat
          :pageSize="pageSize"
          :page="page"
          :displayedRecordsCount="displayedRecordsCount"
          :allRecordsCount="allRecordsCount"
          style="margin-right: 20px;"
        />
        <pagination
          :pageSize="pageSize"
          :value="page"
          @input="$emit('pageChange', $event)"
          :displayedRecordsCount="displayedRecordsCount"
          :allRecordsCount="allRecordsCount"
        />
      </th>
    </tr>
    <!-- /pagination -->

    <tr>
      <!-- titles -->
      <th v-if="selectable" class="th-key_select">&nbsp;</th>
      <th
        v-for="(cellSchema,i) in schema"
        :key="i+'-'+cellSchema.key"
        :class="thClass(cellSchema)"
        @click="onHeadClick(cellSchema)"
      >
        <sorting-arrow v-if="cellSchema.sortable" />
        {{cellSchema.name}}
      </th>
      <th v-if="deletable" class="th-key_delete"><fa-icon icon="trash-alt"/></th>
    </tr>
    <!-- /titles -->

    <!-- search inputs -->
    <tr v-if="hasControlRow" class="filter-inputs-row">
      <th v-if="selectable" class="th-key_select">&nbsp;</th>
      <th
        v-for="(cellSchema,i) in schema"
        :key="i+'-search-'+cellSchema.key"
        :class="thSearchClass(cellSchema)"
      >
        <input
          v-if="cellSchema.searchable"
          class="th-search-input"
          type="text"
          :placeholder="cellSchema.placeholder||'поиск'"
          :value="localSearch[cellSchema.key]"
          @input="onSearchInput($event, cellSchema.key)"
        />
        <filter-selector
          v-if="cellSchema.filterable"
          class="th-filter-select"
          :placeholder='undefined'
          :noSelectedText='undefined'
          :options="cellSchema.filterable"
          value
          @input="onFilterInput($event, cellSchema.key)"
        />
        <span v-if="!cellSchema.searchable&&!cellSchema.filterable">&nbsp;</span>
      </th>
      <th v-if="deletable" class="th-key_delete row_search">&nbsp;</th>
    </tr>
    <!-- /search inputs -->

  </thead>
</template>

<script>
import sortingArrow from '../icons/sorting-arrow';
import filterSelector from './filter-selector';
import pagesStat from './pages-stat';
import pagination from './pagination';

export default {

  components: {sortingArrow, filterSelector, pagesStat, pagination},

  props: {
    schema:       { type: Array  },
    deletable:    { type: Boolean },
    selectable:   { type: Boolean },
    multisort:    { type: Boolean },
    sort:         { type: [Object, Array] }, // {key, direction} || [{key, direction}, ...] for multisort
    search:       { type: Object },
    // pagination
    pageSize:     { type: Number, default: 0 },
    page:         { type: Number, default: 0 },
    displayedRecordsCount: { type: Number, default: 0 },
    allRecordsCount: { type: Number, default: 0 },
  },

  beforeMount(){
    this.localSort = this.sort || {};
    this.localSearch = this.search || {};
  },

  watch: {
    sort(){ this.localSort = this.sort || null; },
    search(){ this.localSearch = this.search || {}; },
    page(){ this.localPage = this.page; },
  },

  data(){ return {
    localSort: {},  // {key: direction}
    localSearch: {}, // {key: string}
    localFilter: {}, // {key: string}
    localPage: 0,
  }; },

  computed: {
    colsCount(){
      let count = this.schema.length;
      if(this.selectable) count++;
      if(this.deletable) count++;
      return count;
    },
    isSearchable(){
      if(!this.schema) return false;
      // at least one record has "searchable" flag set to true
      return Boolean( this.schema.find(r => r.searchable) );
    },
    isfilterable(){
      if(!this.schema) return false;
      // at least one record has "filterable" flag
      return Boolean( this.schema.find(r => Boolean(r.filterable)) );
    },
    hasControlRow(){ return this.isSearchable || this.isfilterable; },
    outputSort(){
      if(!this.localSort) return;
      const sortArr = Object.entries(this.localSort).map( e => ({key: e[0], direction: e[1]}) );
      return this.multisort? sortArr : sortArr[0];
    },
  },

  methods: {
    assignSearch(arr){ // {key,search}[]
      if(!arr) return this.localSearch = {};
      this.localSearch = arr.reduce( (res, el) => ({...res, [el.key]: el.search}), {} );
    },
    assignSort(inputSort){
      const sort = {};
      if(inputSort){
        if(!(inputSort instanceof Array)) throw new Error("Invalid type of 'search', must be array");
        inputSort.forEach(sortItem => { sort[sortItem.key] = sortItem.direction; });
      }
      this.localSort = sort;
    },
    clone(data){ return JSON.parse(JSON.stringify(data)); },
    normKey(key){ return key.replace(/\./g, '--'); },
    thClass(schema){
      let css = 'th-key_' + this.normKey(schema.key);
      if(schema.sortable){
        css += ' th-sortable';
        const direction = this.localSort[schema.key];
        if(direction) css += ' sort-' + direction;
      }
      return css;
    },
    thSearchClass(schema){ return `th-key_${this.normKey(schema.key)} row_search`; },
    addSort(schema){
      // debugger
      const direction = (this.localSort[schema.key] === 'asc')? 'desc' : 'asc';

      let sort;
      if(this.multisort){
        sort = this.clone(this.localSort); // clone to trigger reactivity later
        sort[schema.key] = direction;
      } else {
        sort = {[schema.key]: direction};
      }

      this.localSort = sort;// trigger reactivity
      this.$emit('sort', this.outputSort);
    },
    onHeadClick(schema){
      if(schema.sortable) this.addSort(schema);
    },
    onSearchInput(ev, keyname){
      const val = ev.target.value;
      // reassign to trigger reactivity
      this.localSearch = {...this.localSearch, [keyname]: val};
      // reformat to fire event
      const searchEvent = Object.entries(this.localSearch).filter(e => e[1]).map(e => ({key: e[0], value:e[1]}));
      this.$emit('searchInput', searchEvent);
    },
    onFilterInput(val, keyname){
      // reassign to trigger reactivity
      this.localFilter = {...this.localFilter, [keyname]: val};
      // reformat to fire event
      const filter = Object.entries(this.localFilter)
        .filter(el => el[1] !== undefined)
        .map( el => ({key: el[0], value: el[1]}) );
      this.$emit('filterInput', filter);
    },
  },


};
</script>

<style lang="scss">
  .mk-table{
    th{border-bottom-width: 1px !important;}
    tr:last-child th{border-bottom-width: 2px !important;}
  }

  th.th-sortable{
    position: relative;
    padding-right: 24px;

    svg{
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }

  .th-key_pagination{
    font-weight: normal;
  }

  .filter-inputs-row{
    th{
      padding: 2px !important;
    }

  }
  .th-search-input,
  .th-filter-select {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 1px 5px;
    margin: 0;
  }
//  th.th-key_pagination{
//    text-align: right;
//  }

</style>

